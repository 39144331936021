import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { initialValues as defaultInitialValues } from './initialValues';
import SharedBuyerForm from './SharedBuyerForm';

function CreateBuyerForm({
  initialFormState, contract,
}) {
  return (
    <SharedBuyerForm
      mode="create"
      initialValues={initialFormState}
      contract={contract}
    />
  );
}

function EditBuyerForm({
  initialFormState, contract,
}) {
  const prepareFormValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((item) => prepareFormValues(item));
    } if (typeof values === 'object' && values !== null) {
      // Check if object is a Firestore timestamp-like object
      if ('seconds' in values && (values.nanoseconds || values.nanoseconds === 0)) {
        // Convert to Date object; alternatively, you can use moment.unix(values.seconds)
        return moment.unix(values.seconds);
      }

      // If not a timestamp object, recursively process each property
      const deserializedValues = {};
      Object.keys(values).forEach((key) => {
        deserializedValues[key] = prepareFormValues(values[key]);
      });
      return deserializedValues;
    }
    return values;
  };
  return (
    <SharedBuyerForm
      mode="edit"
      initialValues={prepareFormValues(initialFormState)}
      contract={contract}
    />
  );
}

export default function BuyerForm() {
  const location = useLocation();
  const {
    mode = 'create', initialValues = defaultInitialValues, contract,
  } = location.state || {};

  if (mode === 'create') {
    return (
      <CreateBuyerForm
        initialFormState={initialValues}
        contract={contract}
      />
    );
  }
  return (
    <EditBuyerForm
      initialFormState={initialValues}
      contract={contract}
    />
  );
}
