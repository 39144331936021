import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import autonoom from 'files/logo_pour_fond_clair.svg';
import dots from 'files/dots.png';
import { useTheme } from '@emotion/react';
import profile1 from 'files/portrait-01.png';
import profile2 from 'files/portrait-02.png';
import profile3 from 'files/portrait-03.png';
import laboxlogo from 'files/laboxlogo.jpg';
import barreaunantes from 'files/barreaunantes.png';
import visuelCoupe from 'files/visuel_coupe_pour_fond_clair-1000px.png';

function AutonoomPresentation() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: '100%',
        gap: 6,
        width: '100%',
      }}
    >
      <Box sx={{
        textAlign: 'center',
        pb: 1,
        pt: { xs: 10, md: 15 },
        width: '100%',
        mt: { xs: -10, md: -12 },
      }}
      >
        <img style={{ width: '100%', maxWidth: 300, height: 'auto' }} src={autonoom} alt="Autonoom Logo" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pb: 5,
        px: 5,
        width: '100%',
        justifyContent: 'flex-start',
      }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: 40, md: 100 },
            height: 'auto',
            display: { xs: 'none', md: 'block' },
          }}
          src={dots}
          alt="dots"
        />
        <Box sx={{
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          textAlign: 'center',
          ml: { xs: 0, md: -12 },
          flexGrow: 1,
        }}
        >
          {/* <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.h6.fontSize,
                md: theme.typography.h6.fontSize,
              },
            }}
          >
            Bienvenue !
          </Typography> */}
          <Link
            sx={{
              maxWidth: {
                xs: '100%', md: 600, lg: 800, xl: 1200,
              },
              fontWeight: 'bold',
              mx: 1,
              fontSize: {
                xs: theme.typography.h6.fontSize,
                md: theme.typography.h4.fontSize,
              },
            }}
            href="https://www.informateurjudiciaire.fr/actualites/ces-avocats-qui-sont-aussi-des-entrepreneurs/"
            target="_blank"
            color="link.main"
          >
            AUTONOOM gagne le prix du Jury de l’Incubateur du Barreau de Nantes
          </Link>
          <Typography>
            Et ce n’est que le début...
          </Typography>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pt: 5,
        pb: 5,
        gap: 8,
        backgroundColor: '#f5f5f7',
      }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h4.fontSize,
          },
        }}
        >
          L’Équipe
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: { xs: 1, md: 6 },
          justifyContent: 'center',
        }}
        >
          <Profile
            profileImg={profile1}
            name="Noémie CHANSON"
            title="Avocate fondatrice"
            link="https://www.linkedin.com/in/no%C3%A9mie-chanson-a1089498/"
          />
          <Profile
            profileImg={profile2}
            name="Alexis ROYER"
            title="Avocat fondateur"
            link="https://www.linkedin.com/in/alexis-royer-350539180/"
          />
          <Profile
            profileImg={profile3}
            name="Antoine AURIBAULT"
            title="Associé fondateur"
            link="https://www.linkedin.com/in/antoineauribault/"
          />
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pt: 5,
        pb: 5,
        gap: 8,
      }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h4.fontSize,
          },
        }}
        >
          Les partenaires
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: { xs: 1, md: 6 },
          justifyContent: 'center',
        }}
        >
          <Profile
            profileImg={laboxlogo}
            name="Labox Applications"
            title="Les développeurs"
            link="https://www.labox-apps.com"
          />
          <Profile
            profileImg={barreaunantes}
            name="Barreau de Nantes"
            title="L’incubateur"
            link="https://www.barreaunantes.fr"
          />
          <Profile profileImg={profile2} name="Greg" title="La love monnaie" />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pb: 5,
        pt: 5,
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: '#f5f5f7',
      }}
      >
        <Typography
          color="link.main"
          sx={{
            flexGrow: 1,
            fontWeight: 'bold',
            textAlign: 'center',
            ml: { xs: 8, sm: 12 },
            fontSize: {
              xs: theme.typography.h6.fontSize,
              md: theme.typography.h4.fontSize,
            },
          }}
        >
          À TRÈS BIENTÔT !
        </Typography>
        <Box
          component="img"
          sx={{
            alignSelf: 'flex-end',
            width: { xs: 60, sm: 100 },
            height: 'auto',
          }}
          src={visuelCoupe}
          alt="visuelCoupe"
        />
      </Box>

    </Box>
  );
}

function Profile({
  profileImg, name, title, link,
}) {
  const theme = useTheme();

  const content = (
    <>
      <Box
        component="img"
        sx={{
          width: 'auto', height: 200,
        }}
        src={profileImg}
        alt={name}
      />
      <Typography sx={{
        fontWeight: 'bold',
        color: 'link.main',
        fontSize: {
          xs: theme.typography.subtitle1.fontSize,
          md: theme.typography.h4.fontSize,
        },
      }}
      >
        {name}
      </Typography>
      <Typography sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
    </>
  );

  if (link) {
    return (
      <Link href={link} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          minWidth: 300,
        }}
        >
          {content}
        </Box>
      </Link>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      minWidth: 300,
    }}
    >
      {content}
    </Box>
  );
}

export default AutonoomPresentation;
