import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Link as MUILink, Paper } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import EmailPasswordLoginForm from './EmailPasswordLoginForm';
import SocialSigninCard from './SocialSignInCard';
import CreateAccountForm from './CreateAccountForm';
import PasswordForgottenCard from './PasswordForgottenCard';

export default function SignIn(props) {
  const {
    t, globalActions, navigate, location, Link, auth, authProvider,
    analytics, gtcuCookieName, ourSignInWithPopup, signinCallback,
    signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification,
    logEvent, closeSignin,
  } = props;
  const [emailPasswordCardType, setemailPasswordCardType] = useState('signin');
  const [tab, setTab] = useState('signin');
  const [afterSignInRedirect] = useState(
    location.state?.afterSignInRedirect,
  );

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <SocialSigninCard
        afterSignInRedirect={afterSignInRedirect}
        t={t}
        analytics={analytics}
        navigate={navigate}
        Link={Link}
        auth={auth}
        authProvider={authProvider}
        gtcuCookieName={gtcuCookieName}
        ourSignInWithPopup={ourSignInWithPopup}
        signinCallback={signinCallback}
      />

      {emailPasswordCardType === 'signin' && (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          gap: 1,
          width: '100%',
          maxWidth: 670,
        }}
      >
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} centered>
              <Tab label={t('signin.login')} value="signin" />
              <Tab label={t('signin.register')} value="register" />
            </TabList>
          </Box>
          <TabPanel value="signin">
            <EmailPasswordLoginForm
              auth={auth}
              afterSignInRedirect={afterSignInRedirect}
              setemailPasswordCardType={setemailPasswordCardType}
              navigate={navigate}
              t={t}
              analytics={analytics}
              Link={Link}
              gtcuCookieName={gtcuCookieName}
              globalActions={globalActions}
              signInWithEmailAndPassword={signInWithEmailAndPassword}
              signinCallback={signinCallback}
            />
          </TabPanel>
          <TabPanel value="register">
            <CreateAccountForm
              setemailPasswordCardType={setemailPasswordCardType}
              auth={auth}
              t={t}
              Link={Link}
              analytics={analytics}
              gtcuCookieName={gtcuCookieName}
              createUserWithEmailAndPassword={createUserWithEmailAndPassword}
              sendEmailVerification={sendEmailVerification}
              logEvent={logEvent}
              globalActions={globalActions}
              signinCallback={signinCallback}
            />
          </TabPanel>
        </TabContext>
      </Paper>
      )}

      {emailPasswordCardType === 'passwordForgotten' && (
      <PasswordForgottenCard
        setemailPasswordCardType={setemailPasswordCardType}
        t={t}
        auth={auth}
        analytics={analytics}
        Link={Link}
        globalActions={globalActions}
      />
      )}

      <MUILink
        component={Link}
        onClick={closeSignin}
        to="/contact"
        color="primary"
        sx={{ fontWeight: 'bold' }}
      >
        contact@autonoom.fr
      </MUILink>
    </Box>
  );
}
