import React, { useState } from 'react';
import {
  Box, Tabs, Tab, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';
import EntityDetails from 'components/customInputs/EntityDetails';
import IndividualDetails from 'components/customInputs/IndividualDetails';

export default function SellerDetails({ formik, isReadOnly }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(formik.values.seller.sellerType);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    formik.setFieldValue('seller.sellerType', newValue);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 1,
    }}
    >
      <Typography variant="h4">{`3. ${t('saleForm.seller.title')}`}</Typography>
      <Typography variant="h6">{`${t('saleForm.seller.subtitle')} : `}</Typography>
      <Tabs
        disabled={isReadOnly}
        sx={{ alignSelf: 'center', mb: 2, mt: 2 }}
        value={value}
        onChange={handleTabChange}
        aria-label="seller type tabs"
      >
        <Tab label={t('saleForm.seller.individual.individual')} value="individual" />
        <Tab label={t('saleForm.seller.entity.entity')} value="entity" />
      </Tabs>

      {value === 'individual' && (
      <Box>
        <IndividualDetails
          formik={formik}
          isReadOnly={isReadOnly}
          formName="saleForm"
          entityName="seller"
        />
      </Box>
      )}
      {value === 'entity' && (
      <Box>
        <EntityDetails
          formik={formik}
          isReadOnly={isReadOnly}
          formName="saleForm"
          entityName="seller"
        />
      </Box>
      )}
      <AgreeCheckMark
        formik={formik}
        isReadOnly={isReadOnly}
        field="seller.nonProfessionalSellerCertification"
        label={t('saleForm.seller.nonProfessionalSellerCertification')}
        extraText={t('saleForm.seller.nonProfessionalSellerCertificationExtra')}
      />
    </Box>

  );
}
