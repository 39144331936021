import React from 'react';
import {
  Box, TextField, Autocomplete, InputAdornment, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';

export default function EntityDetails({
  formik, isReadOnly, formName, entityName,
}) {
  const { t } = useTranslation();
  const companyType = ['SA', 'SAS', 'SASU', 'SARL', 'EURL', 'Association'];
  const position = ['Président', 'Gérant', 'Directeur Général', 'Membre du Conseil d\'administration', 'Autre'];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
    }}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'stretch',
      }}
      >
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t(`${formName}.${entityName}.entity.companyName`)}
          name={`${entityName}.entity.companyName`}
          value={formik.values[entityName].entity.companyName}
          onChange={formik.handleChange}
          error={formik.touched[entityName]?.entity?.companyName
            && Boolean(formik.errors[entityName]?.entity?.companyName)}
          helperText={formik.touched[entityName]?.entity?.companyName && formik.errors[entityName]?.entity?.companyName}
        />

        {/* Payment Method field */}
        <Autocomplete
          disabled={isReadOnly}
          sx={{ minWidth: { xs: '100%', sm: 400 }, flexGrow: 1 }}
          freeSolo
          options={companyType}
          value={formik.values[entityName].entity.companyType || ''}
          onChange={(event, value) => formik.setFieldValue(`${entityName}.entity.companyType`, value)}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'clear') {
              formik.setFieldValue(`${entityName}.entity.companyType`, '');
            }
          }}
          renderInput={(params) => (
            <TextField
              disabled={isReadOnly}
              label={t(`${formName}.${entityName}.entity.companyType`)}
              onBlur={() => formik.setFieldValue(`${entityName}.entity.companyType`, params.inputProps.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  formik.setFieldValue(`${entityName}.entity.companyType`, params.inputProps.value);
                }
              }}
              error={formik.touched[entityName]?.entity?.companyType
                && Boolean(formik.errors[entityName]?.entity?.companyType)}
              helperText={formik.touched[entityName]?.entity?.companyType
                && formik.errors[entityName]?.entity?.companyType}
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
      </Box>

      {/* Corporate Capital field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.corporateCapital`)}
        name={`${entityName}.entity.corporateCapital`}
        type="number"
        value={formik.values[entityName].entity.corporateCapital}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.entity?.corporateCapital
            && Boolean(formik.errors[entityName]?.entity?.corporateCapital)}
        helperText={formik.touched[entityName]?.entity?.corporateCapital
            && formik.errors[entityName]?.entity?.corporateCapital}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />

      {/* Siren Number field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.sirenNumber`)}
        name={`${entityName}.entity.sirenNumber`}
        value={formik.values[entityName].entity.sirenNumber}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.entity?.sirenNumber
            && Boolean(formik.errors[entityName]?.entity?.sirenNumber)}
        helperText={formik.touched[entityName]?.entity?.sirenNumber && formik.errors[entityName]?.entity?.sirenNumber}
      />

      {/* Address field */}
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t(`${formName}.${entityName}.entity.address_info`)}
        input={(
          <TextField
            disabled={isReadOnly}
            sx={{ width: ' 100%' }}
            label={t(`${formName}.${entityName}.entity.address`)}
            name={`${entityName}.entity.address`}
            value={formik.values[entityName].entity.address}
            onChange={formik.handleChange}
            error={formik.touched[entityName]?.entity?.address && Boolean(formik.errors[entityName]?.entity?.address)}
            helperText={formik.touched[entityName]?.entity?.address && formik.errors[entityName]?.entity?.address}
          />
          )}
      />

      {/* Postal Code field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.postalCode`)}
        name={`${entityName}.entity.postalCode`}
        value={formik.values[entityName].entity.postalCode}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.entity?.postalCode && Boolean(formik.errors[entityName]?.entity?.postalCode)}
        helperText={formik.touched[entityName]?.entity?.postalCode && formik.errors[entityName]?.entity?.postalCode}
      />

      {/* City field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.city`)}
        name={`${entityName}.entity.city`}
        value={formik.values[entityName].entity.city}
        onChange={(event) => {
          const value = event.target.value.toUpperCase();
          formik.setFieldValue(`${entityName}.entity.city`, value);
        }}
        error={formik.touched[entityName]?.entity?.city && Boolean(formik.errors[entityName]?.entity?.city)}
        helperText={formik.touched[entityName]?.entity?.city && formik.errors[entityName]?.entity?.city}
      />

      <Typography sx={{ alignSelf: 'flex-start' }}>
        {`${t(`${formName}.${entityName}.entity.representedBy`)} : `}
      </Typography>

      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'stretch',
      }}
      >
        {/* Last Name field */}
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t(`${formName}.${entityName}.entity.representativeLastName`)}
          name={`${entityName}.entity.representativeLastName`}
          value={formik.values[entityName].entity.representativeLastName}
          onChange={formik.handleChange}
          error={formik.touched[entityName]?.entity?.representativeLastName
            && Boolean(formik.errors[entityName]?.entity?.representativeLastName)}
          helperText={formik.touched[entityName]?.entity?.representativeLastName
            && formik.errors[entityName]?.entity?.representativeLastName}
        />

        {/* First Name field */}
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t(`${formName}.${entityName}.entity.representativeFirstName`)}
          name={`${entityName}.entity.representativeFirstName`}
          value={formik.values[entityName].entity.representativeFirstName}
          onChange={formik.handleChange}
          error={formik.touched[entityName]?.entity?.representativeFirstName
            && Boolean(formik.errors[entityName]?.entity?.representativeFirstName)}
          helperText={formik.touched[entityName]?.entity?.representativeFirstName
            && formik.errors[entityName]?.entity?.representativeFirstName}
        />
      </Box>

      {/* Birth Date field */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isReadOnly}
          sx={{ width: '100%' }}
          label={t(`${formName}.${entityName}.entity.representativeBirthDate`)}
          format="DD/MM/YYYY"
          value={formik.values[entityName].entity.representativeBirthDate}
          onChange={(date) => {
            formik.setFieldValue(`${entityName}.entity.representativeBirthDate`, date);
          }}
          slotProps={{
            textField: {
              name: `${entityName}.entity.representativeBirthDate`,
              error: Boolean(formik.touched[entityName]?.entity?.representativeBirthDate
                && formik.errors[entityName]?.entity?.representativeBirthDate),
              helperText: formik.touched[entityName]?.entity?.representativeBirthDate
              && formik.errors[entityName]?.entity?.representativeBirthDate,
              onBlur: () => formik.setFieldTouched(`${entityName}.entity.representativeBirthDate`, true),
            },
          }}
        />
      </LocalizationProvider>

      {/* Birth Place field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.representativeBirthPlace`)}
        name={`${entityName}.entity.representativeBirthPlace`}
        value={formik.values[entityName].entity.representativeBirthPlace}
        onChange={(event) => {
          const value = event.target.value.toUpperCase();
          formik.setFieldValue(`${entityName}.entity.representativeBirthPlace`, value);
        }}
        error={formik.touched[entityName]?.entity?.representativeBirthPlace
           && Boolean(formik.errors[entityName]?.entity?.representativeBirthPlace)}
        helperText={formik.touched[entityName]?.entity?.representativeBirthPlace
          && formik.errors[entityName]?.entity?.representativeBirthPlace}

      />

      {/* Nationality field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.entity.representativeNationality`)}
        name={`${entityName}.entity.representativeNationality`}
        value={formik.values[entityName].entity.representativeNationality}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.entity?.representativeNationality
          && Boolean(formik.errors[entityName]?.entity?.representativeNationality)}
        helperText={formik.touched[entityName]?.entity?.representativeNationality
          && formik.errors[entityName]?.entity?.representativeNationality}
      />

      <Autocomplete
        disabled={isReadOnly}
        sx={{ minWidth: { xs: '100%', sm: 400 }, flexGrow: 1 }}
        freeSolo
        options={position}
        value={formik.values[entityName].entity.position || ''}
        onChange={(event, value) => formik.setFieldValue(`${entityName}.entity.position`, value)}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'clear') {
            formik.setFieldValue(`${entityName}.entity.position`, '');
          }
        }}
        renderInput={(params) => (
          <TextField
            disabled={isReadOnly}
            label={t(`${formName}.${entityName}.entity.position`)}
            onBlur={() => formik.setFieldValue(`${entityName}.entity.position`, params.inputProps.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                formik.setFieldValue(`${entityName}.entity.position`, params.inputProps.value);
              }
            }}
            error={formik.touched[entityName]?.entity?.position && Boolean(formik.errors[entityName]?.entity?.position)}
            helperText={formik.touched[entityName]?.entity?.position && formik.errors[entityName]?.entity?.position}
                // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />

      {/* KBIS Proof Upload */}
      <InputFileUpload
        isReadOnly={isReadOnly}
        fileBaseName="kbis"
        formik={formik}
        field={`${entityName}.entity.kbisProof`}
        label={`${t(`${formName}.${entityName}.entity.kbisProof`)} : `}
        accept="image/jpeg,image/png,application/pdf"
      />

      {/* Identity Proof Upload */}
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t(`${formName}.${entityName}.entity.identityProof_info`)}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t(`fileName.${entityName}Id`)}
            formik={formik}
            field={`${entityName}.entity.identityProof`}
            label={`${t(`${formName}.${entityName}.entity.identityProof`)} : `}
            accept="image/jpeg,image/png"
          />
          )}
      />

      {/* Company Declaration Checkbox */}
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t(`${formName}.${entityName}.entity.companyDeclaration_info`)}
        input={(
          <AgreeCheckMark
            isReadOnly={isReadOnly}
            formik={formik}
            field={`${entityName}.entity.companyDeclaration`}
            label={t(`${formName}.${entityName}.entity.companyDeclaration`)}
            extraText={t(`${formName}.${entityName}.entity.companyDeclarationExtra`)}
          />
          )}
      />

    </Box>
  );
}
