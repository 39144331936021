import React, { useState, useMemo, useContext } from 'react';

export const SignInContext = React.createContext();

export function SignInProvider({ children }) {
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);
  const [signinCallback, setSigninCallback] = useState(() => () => {});

  const openSignin = (callback = () => () => {}) => {
    setSigninCallback(callback);
    setSignInDialogOpen(true);
  };

  // Memoize the context value
  const contextValue = useMemo(() => ({
    openSignin,
    signInDialogOpen,
    setSignInDialogOpen,
    setSigninCallback,
    signinCallback,
  }), [signInDialogOpen, signinCallback]);

  return (
    <SignInContext.Provider value={contextValue}>
      {children}
    </SignInContext.Provider>
  );
}

// Hook to use the context
export const useSignIn = () => useContext(SignInContext);
