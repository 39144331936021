import React from 'react';
import {
  Autocomplete,
  Box, FormControl, FormHelperText, InputLabel,
  MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import MaskedInput from 'react-text-mask';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function InformationsVehicle({ formik, isReadOnly }) {
  const { t } = useTranslation();

  const handleLicenseStatusChange = (event, newAlignment) => {
    formik.setFieldValue('vehicle.licenseStatus', newAlignment);
  };

  const vehicleBrands = [
    'Peugeot',
    'Dacia',
    'Ford',
    'Toyota',
    'Citroën',
    'Chevrolet',
    'Ferrari',
    'Volkswagen',
    'Hyundai',
    'Nissan',
    'Volvo',
    'Mazda',
    'Mitsubishi',
    'BMW ',
    'Mercedes-Benz ',
    'Lexus',
    'Audi',
    'Jaguar',
    'Land Rover',
    'Jeep',
    'Fiat',
    'Honda',
    'Suzuki',
    'Subaru',
    'Isuzu',
    'Kia',
    'Skoda',
    'Seat',
    'Bentley',
    'Aston Martin',
    'Lotus',
    'Maserati',
    'Porsche ',
    'Smart',
    'Tesla ',
    'Alfa Romeo ',
    'Dodge ',
    'GMC ',
    'Infiniti ',
    'Lamborghini ',
    'Lincoln ',
    'Mini ',
    'Opel ',
    'Rolls-Royce ',
    'Saab ',
    'Renault',
  ];

  const vehicleFuels = ['Essence', 'Diesel', 'Hybride', 'Electrique', 'GPL'];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="h4" x={{ fontWeigth: 'bold' }}>{`1. ${t('saleForm.vehicle.title')}`}</Typography>
      <Typography variant="h6" x={{ fontWeigth: 'bold' }}>{`1.1 ${t('saleForm.vehicle.sub_title')} : `}</Typography>

      <Box sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
      >
        <InputWithTooltip
          addedStyle={{ flexGrow: 1 }}
          message={t('saleForm.vehicle.brand_info')}
          input={(
            <Autocomplete
              disabled={isReadOnly}
              freeSolo
              options={vehicleBrands}
              value={formik.values.vehicle.brand || ''}
              onChange={(event, value) => formik.setFieldValue('vehicle.brand', value)}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === 'clear') {
                  formik.setFieldValue('vehicle.brand', '');
                }
              }}
              renderInput={(params) => (
                <TextField
                  disabled={isReadOnly}
                  label={t('saleForm.vehicle.brand')}
                  name="vehicle.brand"
                  onBlur={() => formik.setFieldValue('vehicle.brand', params.inputProps.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      event.stopPropagation();
                      formik.setFieldValue('vehicle.brand', params.inputProps.value);
                    }
                  }}
                  error={formik.touched.vehicle?.brand && Boolean(formik.errors.vehicle?.brand)}
                  helperText={formik.touched.vehicle?.brand && formik.errors.vehicle?.brand}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                />
              )}
            />
          )}
        />

        <InputWithTooltip
          addedStyle={{ flexGrow: 1 }}
          message={t('saleForm.vehicle.model_info')}
          input={(
            <TextField
              disabled={isReadOnly}
              sx={{ width: '100%' }}
              label={t('saleForm.vehicle.model')}
              name="vehicle.model"
              value={formik.values.vehicle.model}
              onChange={formik.handleChange}
              error={formik.touched.vehicle?.model && Boolean(formik.errors.vehicle?.model)}
              helperText={formik.touched.vehicle?.model && formik.errors.vehicle?.model}
            />
          )}
        />

      </Box>

      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography>{`${t('saleForm.vehicle.licenseStatus')} : `}</Typography>
        <ToggleButtonGroup
          color="primary"
          value={formik.values.vehicle.licenseStatus}
          exclusive
          onChange={handleLicenseStatusChange}
          aria-label="vehicle.licenseStatus"
          size="small"
          disabled={isReadOnly}
        >
          <ToggleButton value="withDrivingLicence">{t('saleForm.vehicle.withDrivingLicence')}</ToggleButton>
          <ToggleButton value="withoutDrivingLicence">{t('saleForm.vehicle.withoutDrivingLicence')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.registrationNumber_info')}
        input={(
          <TextField
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('saleForm.vehicle.registrationNumber')}
            name="vehicle.registrationNumber"
            value={formik.values.vehicle.registrationNumber}
            onChange={(event) => {
              const value = event.target.value.toUpperCase();
              formik.setFieldValue('vehicle.registrationNumber', value);
            }}
            error={formik.touched.vehicle?.registrationNumber && Boolean(formik.errors.vehicle?.registrationNumber)}
            helperText={formik.touched.vehicle?.registrationNumber && formik.errors.vehicle?.registrationNumber}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
          />
        )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.registrationDate_info')}
        input={(
          <LocalizationProvider
            dateAdapter={AdapterMoment}
          >
            <DatePicker
              disabled={isReadOnly}
              sx={{ width: '100%' }}
              label={t('saleForm.vehicle.registrationDate')}
              format="DD/MM/YYYY"
              value={formik.values.vehicle.registrationDate}
              onChange={(date) => {
                formik.setFieldValue('vehicle.registrationDate', date);
              }}
              slotProps={{
                textField: {
                  name: 'vehicle.registrationDate',
                  error: Boolean(formik.touched.vehicle?.registrationDate && formik.errors.vehicle?.registrationDate),
                  helperText: formik.touched.vehicle?.registrationDate && formik.errors.vehicle?.registrationDate,
                  onBlur: () => formik.setFieldTouched('vehicle.registrationDate', true),
                },
              }}
            />
          </LocalizationProvider>
        )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.VIN_info')}
        input={(
          <TextField
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('saleForm.vehicle.VIN')}
            name="vehicle.VIN"
            value={formik.values.vehicle.VIN}
            onChange={(event) => {
              const value = event.target.value.toUpperCase();
              formik.setFieldValue('vehicle.VIN', value);
            }}
            error={formik.touched.vehicle?.VIN && Boolean(formik.errors.vehicle?.VIN)}
            helperText={formik.touched.vehicle?.VIN && formik.errors.vehicle?.VIN}
            inputProps={{ pattern: '[A-Z]*' }}
          />
        )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.fiscalPower_info')}
        input={(
          <TextField
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('saleForm.vehicle.fiscalPower')}
            name="vehicle.fiscalPower"
            type="number"
            value={formik.values.vehicle.fiscalPower}
            onChange={formik.handleChange}
            error={formik.touched.vehicle?.fiscalPower && Boolean(formik.errors.vehicle?.fiscalPower)}
            helperText={formik.touched.vehicle?.fiscalPower && formik.errors.vehicle?.fiscalPower}
          />
        )}
      />

      <Autocomplete
        freeSolo
        disabled={isReadOnly}
        options={vehicleFuels}
        value={formik.values.vehicle.fuelType || ''}
        onChange={(event, value) => formik.setFieldValue('vehicle.fuelType', value)}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'clear') {
            formik.setFieldValue('vehicle.fuelType', '');
          }
        }}
        renderInput={(params) => (
          <TextField
            label={t('saleForm.vehicle.fuelType')}
            onBlur={() => formik.setFieldValue('vehicle.fuelType', params.inputProps.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                formik.setFieldValue('vehicle.fuelType', params.inputProps.value);
              }
            }}
            error={formik.touched.vehicle?.fuelType && Boolean(formik.errors.vehicle?.fuelType)}
            helperText={formik.touched.vehicle?.fuelType && formik.errors.vehicle?.fuelType}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.gearbox_info')}
        input={(
          <FormControl
            fullWidth
            error={formik.touched.vehicle?.gearbox && Boolean(formik.errors.vehicle?.gearbox)}
          >
            <InputLabel id="gearbox-label">{t('saleForm.vehicle.gearbox')}</InputLabel>
            <Select
              disabled={isReadOnly}
              labelId="gearbox-label"
              label={t('saleForm.vehicle.gearbox')}
              name="vehicle.gearbox"
              value={formik.values.vehicle.gearbox}
              onChange={formik.handleChange}
            >
              <MenuItem value="manual">{t('saleForm.vehicle.manual')}</MenuItem>
              <MenuItem value="automatic">{t('saleForm.vehicle.automatic')}</MenuItem>
            </Select>
            <FormHelperText>
              {formik.touched.vehicle?.gearbox && formik.errors.vehicle?.gearbox}
            </FormHelperText>
          </FormControl>
              )}
      />

      <FormControl
        fullWidth
        error={formik.touched.vehicle?.doorCount && Boolean(formik.errors.vehicle?.doorCount)}
      >
        <InputLabel id="doorCount-label">{t('saleForm.vehicle.doorCount')}</InputLabel>
        <Select
          disabled={isReadOnly}
          labelId="doorCount-label"
          label={t('saleForm.vehicle.doorCount')}
          name="vehicle.doorCount"
          value={formik.values.vehicle.doorCount}
          onChange={formik.handleChange}
        >
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="6+">6+</MenuItem>
          <MenuItem value="Deux-roues/non-applicable">Deux-roues/non-applicable</MenuItem>
        </Select>
        <FormHelperText>
          {formik.touched.vehicle?.doorCount && formik.errors.vehicle?.doorCount}
        </FormHelperText>
      </FormControl>

      <TextField
        disabled={isReadOnly}
        sx={{ width: '100%' }}
        label={t('saleForm.vehicle.technicalInformation')}
        name="vehicle.technicalInformation"
        value={formik.values.vehicle.technicalInformation}
        onChange={formik.handleChange}
        error={formik.touched.vehicle?.technicalInformation && Boolean(formik.errors.vehicle?.technicalInformation)}
        helperText={formik.touched.vehicle?.technicalInformation && formik.errors.vehicle?.technicalInformation}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.registrationCertificate_info')}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t('fileName.registrationCertificate')}
            formik={formik}
            field="vehicle.registrationCertificate"
            label={`${t('saleForm.vehicle.registrationCertificate')} : `}
            accept="image/jpeg,image/png,application/pdf"
          />
        )}
      />
    </Box>
  );
}

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      ref={(inputRef) => {
        ref(inputRef ? inputRef.inputElement : null);
      }}
      mask={[/[A-Z]/i, /[A-Z]/i, '-', /\d/, /\d/, /\d/, '-', /[A-Z]/i, /[A-Z]/i]}
      placeholderChar={'\u2000'}
      guide
      keepCharPositions
      onChange={onChange}
    />
  );
});

export default InformationsVehicle;
