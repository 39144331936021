import React from 'react';
import {
  Box, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';

export default function IndividualDetails({
  formik, isReadOnly, formName, entityName,
}) {
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
    }}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'stretch',
      }}
      >
        {/* Last Name field */}
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t(`${formName}.${entityName}.individual.lastName`)}
          name={`${entityName}.individual.lastName`}
          value={formik.values[entityName].individual.lastName}
          onChange={formik.handleChange}
          error={formik.touched[entityName]?.individual?.lastName
            && Boolean(formik.errors[entityName]?.individual?.lastName)}
          helperText={formik.touched[entityName]?.individual?.lastName
            && formik.errors[entityName]?.individual?.lastName}
        />

        {/* First Name field */}
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t(`${formName}.${entityName}.individual.firstName`)}
          name={`${entityName}.individual.firstName`}
          value={formik.values[entityName].individual.firstName}
          onChange={formik.handleChange}
          error={formik.touched[entityName]?.individual?.firstName
            && Boolean(formik.errors[entityName]?.individual?.firstName)}
          helperText={formik.touched[entityName]?.individual?.firstName
            && formik.errors[entityName]?.individual?.firstName}
        />
      </Box>

      {/* Birth Date field */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isReadOnly}
          sx={{ width: '100%' }}
          label={t(`${formName}.${entityName}.individual.birthDate`)}
          format="DD/MM/YYYY"
          value={formik.values[entityName].individual.birthDate}
          onChange={(date) => {
            formik.setFieldValue(`${entityName}.individual.birthDate`, date);
          }}
          slotProps={{
            textField: {
              name: `${entityName}.individual.birthDate`,
              error: Boolean(formik.touched[entityName]?.individual?.birthDate
                && formik.errors[entityName]?.individual?.birthDate),
              helperText: formik.touched[entityName]?.individual?.birthDate
              && formik.errors[entityName]?.individual?.birthDate,
              onBlur: () => formik.setFieldTouched(`${entityName}.individual.birthDate`, true),
            },
          }}
        />
      </LocalizationProvider>

      {/* Birth Place field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.individual.birthPlace`)}
        name={`${entityName}.individual.birthPlace`}
        value={formik.values[entityName].individual.birthPlace}
        onChange={(event) => {
          const value = event.target.value.toUpperCase();
          formik.setFieldValue(`${entityName}.individual.birthPlace`, value);
        }}
        error={formik.touched[entityName]?.individual?.birthPlace
            && Boolean(formik.errors[entityName]?.individual?.birthPlace)}
        helperText={formik.touched[entityName]?.individual?.birthPlace
            && formik.errors[entityName]?.individual?.birthPlace}

      />

      {/* Nationality field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.individual.nationality`)}
        name={`${entityName}.individual.nationality`}
        value={formik.values[entityName].individual.nationality}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.individual?.nationality
            && Boolean(formik.errors[entityName]?.individual?.nationality)}
        helperText={formik.touched[entityName]?.individual?.nationality
            && formik.errors[entityName]?.individual?.nationality}
      />

      {/* Address field */}
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t(`${formName}.${entityName}.individual.address_info`)}
        input={(
          <TextField
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t(`${formName}.${entityName}.individual.address`)}
            name={`${entityName}.individual.address`}
            value={formik.values[entityName].individual.address}
            onChange={formik.handleChange}
            error={formik.touched[entityName]?.individual?.address
                && Boolean(formik.errors[entityName]?.individual?.address)}
            helperText={formik.touched[entityName]?.individual?.address
                && formik.errors[entityName]?.individual?.address}
          />
          )}
      />

      {/* Postal Code field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.individual.postalCode`)}
        name={`${entityName}.individual.postalCode`}
        value={formik.values[entityName].individual.postalCode}
        onChange={formik.handleChange}
        error={formik.touched[entityName]?.individual?.postalCode
            && Boolean(formik.errors[entityName]?.individual?.postalCode)}
        helperText={formik.touched[entityName]?.individual?.postalCode
            && formik.errors[entityName]?.individual?.postalCode}

      />

      {/* City field */}
      <TextField
        disabled={isReadOnly}
        label={t(`${formName}.${entityName}.individual.city`)}
        name={`${entityName}.individual.city`}
        value={formik.values[entityName].individual.city}
        onChange={(event) => {
          const value = event.target.value.toUpperCase();
          formik.setFieldValue(`${entityName}.individual.city`, value);
        }}
        error={formik.touched[entityName]?.individual?.city && Boolean(formik.errors[entityName]?.individual?.city)}
        helperText={formik.touched[entityName]?.individual?.city && formik.errors[entityName]?.individual?.city}

      />

      {/* Identity Proof Upload */}
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t(`${formName}.${entityName}.individual.identityProof_info`)}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t(`fileName.${entityName}Id`)}
            formik={formik}
            field={`${entityName}.individual.identityProof`}
            label={`${t(`${formName}.${entityName}.individual.identityProof`)} : `}
            accept="image/jpeg,image/png"
          />
          )}
      />

      {/* Owner Declaration Checkbox */}
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field={`${entityName}.individual.ownerDeclaration`}
        label={t(`${formName}.${entityName}.individual.ownerDeclaration`)}
        extraText={t(`${formName}.${entityName}.individual.ownerDeclarationExtra`)}
      />

    </Box>
  );
}
