import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@emotion/react';
import ClearIcon from '@mui/icons-material/Clear';

export default function AgreeCheckMark({
  formik, field, label, isReadOnly, extraText = '', openAtStart = false,
}) {
  const [open, setOpen] = useState(openAtStart);
  const { t } = useTranslation();
  const theme = useTheme();

  function getNestedValue(obj, path) {
    return path.split('.').reduce((currentObject, key) => (currentObject ? currentObject[key] : undefined), obj);
  }

  const fieldError = getNestedValue(formik.errors, field);
  const fieldTouched = getNestedValue(formik.touched, field);

  const handleAccept = () => {
    formik.setFieldValue(field, true);
    setOpen(false);
  };

  const handleDecline = () => {
    formik.setFieldValue(field, false);
    setOpen(false);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      border: 1,
      borderRadius: 1,
      p: 1,
      borderColor: fieldTouched && fieldError ? '#db5353' : 'gray.main',
    }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
      >
        <Checkbox
          disabled={isReadOnly}
          name={field}
          checked={getNestedValue(formik.values, field)}
        />
        <Typography sx={{ width: '100%', textAlign: 'left' }}>{label}</Typography>
      </Box>
      {fieldTouched && fieldError && (
        <Typography color="error">{fieldError}</Typography>
      )}
      <Dialog open={open} maxWidth="xl">
        <DialogContent>
          <Typography sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.h6.fontSize,
            },
            maxWidth: 800,
            m: { xs: 1, sm: 3, md: 6 },
            whiteSpace: 'pre-line',
          }}
          >
            {label}
          </Typography>
          {extraText && (
            <Typography sx={{
              fontSize: {
                xs: theme.typography.body2.fontSize,
                md: theme.typography.body1.fontSize,
              },
              maxWidth: 800,
              m: { xs: 1, sm: 3, md: 6 },
              whiteSpace: 'pre-line',
            }}
            >
              {extraText}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isReadOnly}
            variant="contained"
            size="large"
            onClick={handleAccept}
            endIcon={<DoneIcon />}
          >
            {t('read_and_accepted')}
          </Button>
          <Button
            disabled={isReadOnly}
            variant="outlined"
            color="error"
            size="large"
            onClick={handleDecline}
            endIcon={<ClearIcon />}
          >
            {t('do_not_accept')}
          </Button>
          <Button variant="text" size="large" onClick={() => setOpen(false)}>{t('close')}</Button>
        </DialogActions>

      </Dialog>
    </Box>
  );
}
