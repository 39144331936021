import { useEffect } from 'react';

export default function ScrollTracker({ sectionRefs, onActiveSectionChange }) {
  useEffect(() => {
    const handleScroll = () => {
      let activeSection = null;
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const halfwayScreenHeight = window.innerHeight / 2;

      // Determine which section is currently in view
      sectionRefs.forEach(({ ref, name }) => {
        if (ref.current) {
          const sectionTop = ref.current.offsetTop;
          const sectionHeight = ref.current.offsetHeight;

          // Adjust the condition to check if the section top passed halfway through the screen
          const isSectionTopPastHalfway = scrollPosition + halfwayScreenHeight >= sectionTop;
          const isSectionBottomAboveHalfway = scrollPosition + halfwayScreenHeight < sectionTop + sectionHeight;

          if (isSectionTopPastHalfway && isSectionBottomAboveHalfway) {
            activeSection = name;
          }
        }
      });

      // Notify the parent component about the active section
      if (activeSection !== null) {
        onActiveSectionChange(activeSection);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionRefs, onActiveSectionChange]);

  return null; // This component does not render anything
}
