import * as Yup from 'yup';

const photoValidationNotRequired = (value) => value.length === 0
  || value.every((file) => /\.(jpe?g|png)$/i.test(file.name));

const seenVehicleSchema = (t) => Yup.object().shape({
  date: Yup.date().required(t('required')),
  testDrive: Yup.string().required(t('required')),
  testDriveDate: Yup.object()
    .when('testDrive', {
      is: true,
      then: () => Yup.date().required(t('required')),
      otherwise: () => Yup.object().notRequired(),
    }),
  vehicleConformToDescriptionAndPhotographs: Yup.string().required(t('required')),
  vehicleConformToDescriptionAndPhotographsMessage: Yup.object()
    .when('vehicleConformToDescriptionAndPhotographs', {
      is: false,
      then: () => Yup.string().required(t('required')),
      otherwise: () => Yup.object().notRequired(),
    }),
  furtherComments: Yup.string(),
  addedPhotos: Yup.array()
    .test('is-valid-photo', t('formValidations.jpeg_or_png'), photoValidationNotRequired),
});

const vehicleSchema = (t) => Yup.object().shape({
  vehicleSeen: Yup.string().required(t('required')),
  seenVehicle: Yup.object()
    .when('vehicleSeen', ([vehicleSeenValue], schema) => (
      vehicleSeenValue === true
        ? seenVehicleSchema(t) : Yup.object().notRequired())),
});

const saleDetailsSchema = (t) => Yup.object().shape({
  confirmDeliveryDate: Yup.boolean().oneOf([true], t('required')),
  confirmPaymentMethod: Yup.boolean().oneOf([true], t('required')),
  confirmKnowledgeofAlltransmittedInfo: Yup.boolean().oneOf([true], t('required')),
});

const individualSchema = (t) => Yup.object().shape({
  lastName: Yup.string().required(t('required')),
  firstName: Yup.string().required(t('required')),
  birthDate: Yup.date().required(t('required')),
  birthPlace: Yup.string().required(t('required')),
  nationality: Yup.string().required(t('required')),
  address: Yup.string().required(t('required')),
  postalCode: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  identityProof: Yup.mixed().required(t('required')),
  ownerDeclaration: Yup.boolean().oneOf([true], t('required')),
});

const entitySchema = (t) => Yup.object().shape({
  companyType: Yup.string().required(t('required')),
  companyName: Yup.string().required(t('required')),
  corporateCapital: Yup.number().required(t('required')),
  sirenNumber: Yup.string().required(t('required')),
  address: Yup.string().required(t('required')),
  postalCode: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  representativeLastName: Yup.string().required(t('required')),
  representativeFirstName: Yup.string().required(t('required')),
  representativeBirthDate: Yup.date().required(t('required')),
  representativeBirthPlace: Yup.string().required(t('required')),
  representativeNationality: Yup.string().required(t('required')),
  position: Yup.string().required(t('required')),
  kbisProof: Yup.mixed().required(t('required')),
  identityProof: Yup.mixed().required(t('required')),
  companyDeclaration: Yup.boolean().oneOf([true], t('required')),
});

const buyerSchema = (t) => Yup.object().shape({
  buyerType: Yup.string().oneOf(['individual', 'entity'], t('required'))
    .required(t('required')),
  individual: Yup.object()
    .when('buyerType', {
      is: 'individual',
      then: () => individualSchema(t),
      otherwise: () => Yup.object().notRequired(),
    }),
  entity: Yup.object()
    .when('buyerType', {
      is: 'entity',
      then: () => entitySchema(t),
      otherwise: () => Yup.object().notRequired(),
    }),
});

const saleFormalitiesSchema = (t) => Yup.object().shape({
  knowledgeVehicleInsurance: Yup.boolean().oneOf([true], t('required')),
  knowledgeNonApplicationWarrantyHiddenDefects: Yup.boolean().oneOf([true], t('required')),
});

// eslint-disable-next-line import/prefer-default-export
export const validationSchema = (t) => Yup.object().shape({
  vehicle: vehicleSchema(t),
  saleDetails: saleDetailsSchema(t),
  buyer: buyerSchema(t),
  saleFormalities: saleFormalitiesSchema(t),
});
