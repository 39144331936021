// eslint-disable-next-line import/prefer-default-export
export const initialValues = {
  saleDetails: {
    confirmDeliveryDate: false,
    confirmPaymentMethod: false,
    confirmKnowledgeofAlltransmittedInfo: false,
  },
  vehicle: {
    vehicleSeen: false,
    seenVehicle: { // if vehicleSeen is true
      date: null,
      testDrive: false,
      testDriveDate: null, // if testDrive is true
      vehicleConformToDescriptionAndPhotographs: true,
      vehicleConformToDescriptionAndPhotographsMessage: '', // if vehicleConformToDescriptionAndPhotographs is false
      furtherComments: '',
      addedPhotos: [],
    },
  },
  saleFormalities: {
    knowledgeVehicleInsurance: false,
    knowledgeNonApplicationWarrantyHiddenDefects: false,
  },
  buyer: {
    buyerType: 'individual', // 'individual' for a physical person, 'entity' for a legal entity
    individual: {
      lastName: '',
      firstName: '',
      birthDate: null,
      birthPlace: '',
      nationality: '',
      address: '',
      postalCode: '',
      city: '',
      identityProof: null,
      ownerDeclaration: false,
    },
    entity: {
      companyType: '',
      companyName: '',
      corporateCapital: '',
      sirenNumber: '',
      address: '',
      postalCode: '',
      city: '',
      representativeLastName: '',
      representativeFirstName: '',
      representativeBirthDate: null,
      representativeBirthPlace: '',
      representativeNationality: '',
      position: '', // President, Manager, etc.
      kbisProof: null,
      identityProof: null,
      companyDeclaration: false,
    },
  },
};
