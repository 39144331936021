import {
  Box, Dialog, DialogTitle, IconButton,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import {
  Close as CloseIcon, KeyboardArrowLeft, KeyboardArrowRight,
} from '@mui/icons-material';

export default function FilePreviewDialog({
  openDialog, setOpenDialog, filePreviews, currentFileIndex, setCurrentFileIndex,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNextFile = useCallback(() => {
    setCurrentFileIndex((prevIndex) => (prevIndex + 1 < filePreviews.length ? prevIndex + 1 : prevIndex));
  }, [filePreviews, setCurrentFileIndex]);

  const handlePrevFile = useCallback(() => {
    setCurrentFileIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
  }, [setCurrentFileIndex]);

  const currentFile = filePreviews[currentFileIndex];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNextFile();
      } else if (event.key === 'ArrowLeft') {
        handlePrevFile();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNextFile, handlePrevFile]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="image-preview-dialog"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        {currentFile && currentFile.name}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ overflow: 'hidden', textAlign: 'center' }}>
        {currentFile && (
          currentFile.isImage ? (
            <img src={currentFile.url} alt={currentFile.name} style={{ maxWidth: '100%', maxHeight: 'auto' }} />
          ) : (
            <iframe src={currentFile.url} title={currentFile.name} style={{ width: '100%', height: '75vh' }} />
            // <UploadFileIcon />
          )
        )}
        <IconButton
          aria-label="previous file"
          onClick={handlePrevFile}
          disabled={currentFileIndex === 0}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 16,
            transform: 'translateY(-50%)',
            color: 'white',
            background: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="next file"
          onClick={handleNextFile}
          disabled={currentFileIndex === filePreviews.length - 1}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 16,
            transform: 'translateY(-50%)',
            color: 'white',
            background: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <KeyboardArrowRight fontSize="large" />
        </IconButton>
      </Box>
    </Dialog>
  );
}
