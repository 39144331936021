import { v4 as uuidv4 } from 'uuid';
import { extractFilesFromFormikValues, processAndReplaceFiles, uploadWithTimeout } from 'components/utils';
import {
  arrayUnion,
  collection, doc, getDoc, writeBatch,
} from 'firebase/firestore';
import {
  ref as storageRef,
} from 'firebase/storage';
import { logEvent } from 'firebase/analytics';
import { emailTemplate } from 'components/emails/EmailTemplate';

const uploadFiles = async (t, storage, files, basePath) => {
  const uploadPromises = files.map(async (fileData, index) => {
    const fileRef = storageRef(storage, `${basePath}/${fileData.path}/${fileData.file.name}`);
    console.log(`Uploading file ${index + 1}/${files.length}: ${fileData.file.name}`);

    await uploadWithTimeout(t, fileRef, fileData.file);

    console.log(`Finished uploading file ${index + 1}/${files.length}: ${fileData.file.name}`);
  });
  await Promise.all(uploadPromises);
};

export default async function submitForm(
  {
    formik,
    storage,
    firestore,
    setPageStatus,
    user,
    buyerEmail,
    t,
    navigate,
    analytics,
    globalActions,
    clearFormStorage,
    updatingContractId,
    bothPartiesPresent,
  },
) {
  setPageStatus('loading');
  try {
    const allFiles = [];

    extractFilesFromFormikValues(formik.values, allFiles);

    const saleDocId = updatingContractId || uuidv4();
    const sharingId = uuidv4();
    const saleDocPath = `autonoomContracts/${saleDocId}`;

    let valuesToStore = await processAndReplaceFiles(formik.values, allFiles, saleDocPath);
    valuesToStore = { ...valuesToStore };

    const prettyPrinted = JSON.stringify(valuesToStore, null, 2);
    console.log('FINAL VALUES: ', prettyPrinted);

    const batch = writeBatch(firestore);
    const autonoomContractDocRef = doc(firestore, 'autonoomContracts', saleDocId);
    let contractData;

    if (!updatingContractId) {
      contractData = {
        type: 'vehicleSalePrivateIndividuals',
        state: 'ownerSubmittedFirstForm',
        owner: user.uid,
        sharingId,
        sharedToEmail: buyerEmail,
        bothPartiesPresent,
        ownerEmail: user.email,
        ownerForm: {
          submitDate: new Date(),
          values: valuesToStore,
        },
      };
      batch.set(autonoomContractDocRef, contractData);
    } else {
      contractData = {
        owner: user.uid,
        ownerForm: {
          updateDate: new Date(),
          values: valuesToStore,
        },
      };
      batch.update(autonoomContractDocRef, contractData);
    }

    console.log('uploading files');

    await uploadFiles(t, storage, allFiles, saleDocPath);

    console.log('files uploaded');

    const userDataDocRef = doc(firestore, `/users/${user.uid}/private/data`);

    batch.update(userDataDocRef, {
      onGoingContracts: arrayUnion(saleDocId),
    });

    const mailDocRef = doc(collection(firestore, 'mail'));

    if (!bothPartiesPresent) {
      const baseUrl = window.location.origin;
      const path = '/acceptContractSharingInvitation';
      const queryParams = `?sharingId=${sharingId}`;
      const sharingLink = `${baseUrl}${path}${queryParams}`;

      const messageHtml1 = emailTemplate(` <p>${t('sendsaleForm.sale_invite_email_content', {
        sellerName: valuesToStore.seller.sellerType === 'individual'
          ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
          : valuesToStore.seller.entity.companyName,
        vehicleName: valuesToStore.vehicle.model,
        link: ` <p>
        <a href="${sharingLink}"
           style="
             display: inline-block;
             padding: 10px 20px;
             background-color: #14c7bf;
             color: white;
             text-decoration: none;
             border-radius: 5px;
             font-weight: bold;
           ">
            Accepter de compléter le contrat
        </a>
      </p>`,
        linkText: sharingLink,
      })}</p>`);

      if (!updatingContractId) {
        batch.set(mailDocRef, {
          to: buyerEmail,
          message: {
            subject: t('sendsaleForm.sale_invite_email_title'),
            html: messageHtml1,
          },
        });

        const contractShareDocRef = doc(firestore, 'contractShares', sharingId);
        batch.set(contractShareDocRef, {
          contractReference: autonoomContractDocRef.path,
          name: valuesToStore.vehicle.model,
          from: valuesToStore.seller.sellerType === 'individual'
            ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
            : valuesToStore.seller.entity.companyName,
        });
      } else {
        const messageHtml2 = emailTemplate(` <p>${t('sendsaleForm.sale_update_email_content', {
          sellerName: valuesToStore.seller.sellerType === 'individual'
            ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
            : valuesToStore.seller.entity.companyName,
          vehicleName: valuesToStore.vehicle.model,
          link: ` <p>
          <a href="${baseUrl}/myContracts"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Mes contrats
          </a>
        </p>`,
          linkText: `${baseUrl}/myContracts`,
        })}</p>`);

        batch.set(mailDocRef, {
          to: buyerEmail,
          message: {
            subject: t('sendsaleForm.sale_update_email_title'),
            html: messageHtml2,
          },
        });
      }
    }

    await batch.commit();
    clearFormStorage();

    if (bothPartiesPresent && !updatingContractId) {
      const newContract = {
        ...contractData,
        id: saleDocId,
      };
      navigate('/myContracts/contract/acquirerForm', {
        replace: true,
        state: { mode: 'create', contract: newContract },
      });
    } else if (!updatingContractId) {
      navigate('/myContracts', { replace: true });
    } else {
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
      const docSnapshot = await getDoc(autonoomContractDocRef);

      navigate(-1);
      navigate('/myContracts/contract', {
        replace: true,
        state: { contract: { ...docSnapshot.data(), id: docSnapshot.id } },
      });
    }

    logEvent(analytics, updatingContractId ? 'vehicle-sale-contract-updated' : 'vehicle-sale-contract-created', {
      appName: 'Autonoom Web',
      user: user.uid,
    });
  } catch (err) {
    globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err}`, severity: 'error' });
    throw err;
  } finally {
    setPageStatus('done');
  }
}
