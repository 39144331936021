import { Timestamp } from 'firebase/firestore';
import { uploadBytes } from 'firebase/storage';
import moment from 'moment';

export function menuPaths(location) {
  const paths = [
    '/alpha',
    '/menuAccount',
    '/menuContact',
    '/myContracts',
  ];
  return paths.includes(location.pathname);
}

export function noMenuPaths(location) {
  const paths = [
    '/',
  ];
  return paths.includes(location.pathname);
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useIntervalAsync = (fn, ms, useRef, useCallback, useEffect) => {
  const timeout = useRef();
  const mountedRef = useRef(false);

  const run = useCallback(async () => {
    await fn();
    if (mountedRef.current) {
      timeout.current = window.setTimeout(run, ms);
    }
  }, [fn, ms]);

  useEffect(() => {
    mountedRef.current = true;
    run();
    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [run]);
};

export const delay = (ms) => new Promise(
  // eslint-disable-next-line no-promise-executor-return
  (resolve) => setTimeout(resolve, ms),
);

export const timeStampFrom = (obj) => {
  // Check if obj is an instance of Timestamp
  if (obj instanceof Timestamp) {
    return obj;
  }
  // Check if obj is a Date
  if (obj instanceof Date) {
    // Convert Date to seconds and nanoseconds
    const seconds = Math.floor(obj.getTime() / 1000);
    const nanoseconds = (obj.getTime() % 1000) * 1e6;

    return new Timestamp(seconds, nanoseconds);
  }
  // Check for object with 'seconds' and 'nanoseconds', or '_seconds' and '_nanoseconds'
  if (obj && typeof obj === 'object') {
    // eslint-disable-next-line no-underscore-dangle
    const seconds = obj.seconds || obj._seconds;
    // eslint-disable-next-line no-underscore-dangle
    const nanoseconds = obj.nanoseconds || obj._nanoseconds || 0;

    if (typeof seconds === 'number' && typeof nanoseconds === 'number') {
      return new Timestamp(seconds, nanoseconds);
    }
  }
  throw new Error('Invalid object for timestamp conversion');
};

export const displayDate = (obj) => {
  try {
    const timestamp = timeStampFrom(obj);
    return timestamp.toDate().toLocaleDateString();
  } catch (error) {
    return 'Invalid date';
  }
};

const getAspectRatio = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      resolve(aspectRatio);
    };
    img.onerror = reject;
    img.src = event.target.result;
  };
  reader.onerror = reject;
  reader.readAsDataURL(file);
});

const processFile = async (file, fileDatas, basePath) => {
  const fileReplacement = fileDatas.find((f) => f.file === file);
  if (fileReplacement) {
    const replacementObject = {
      name: fileReplacement.file.name,
      storagePath: `${basePath}/${fileReplacement.path}/${fileReplacement.file.name}`,
    };

    if (fileReplacement.file.type.startsWith('image/') && fileReplacement.file.type !== 'application/pdf') {
      replacementObject.aspectRatio = await getAspectRatio(fileReplacement.file);
    }

    return replacementObject;
  }
  return file;
};

export const processAndReplaceFiles = async (valuesObject, fileDatas, basePath) => {
  const processComplexObject = async (obj) => {
    if (obj == null) return null;
    if (obj instanceof File) {
      return processFile(obj, fileDatas, basePath);
    }
    if (moment.isMoment(obj)) return obj.toDate();
    if (typeof obj !== 'object') return obj;

    const promises = Object.keys(obj).map(async (key) => {
      const fileData = fileDatas.find((f) => f.file === obj[key]);
      const newValue = fileData
        ? {
          name: fileData.file.name,
          storagePath: `${basePath}/${fileData.path}/${fileData.file.name}`,
        }
        : obj[key];
      if (fileData && fileData.file instanceof File && fileData.file.type !== 'application/pdf') {
        newValue.aspectRatio = await getAspectRatio(fileData.file);
      }
      return processComplexObject(newValue).then((processedValue) => {
        if (processedValue !== null) {
          return { key, value: processedValue };
        }
        return null;
      });
    });

    const results = await Promise.all(promises);
    const newObj = Array.isArray(obj) ? [] : {};
    results.forEach((result) => {
      if (result !== null) {
        newObj[result.key] = result.value;
      }
    });

    return newObj;
  };

  return processComplexObject(valuesObject);
};

export const uploadWithTimeout = (t, fileRef, file, timeout = 10000) => new Promise((resolve, reject) => {
  const timeoutId = setTimeout(() => {
    reject(new Error(t('upload_timed_out')));
  }, timeout);

  uploadBytes(fileRef, file).then((response) => {
    clearTimeout(timeoutId);
    resolve(response);
  }).catch((error) => {
    clearTimeout(timeoutId);
    reject(error);
  });
});

export function extractFilesFromFormikValues(values, allFiles, parentKey = '') {
  Object.keys(values).forEach((key) => {
    const value = values[key];
    const currentKey = parentKey ? `${parentKey}/${key}` : key;

    if (value instanceof File) {
      allFiles.push({
        file: value,
        formValue: currentKey,
        path: currentKey,
      });
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          allFiles.push({
            file: item,
            formValue: `${currentKey}`,
            path: `ownerForm/${currentKey}`,
          });
        } else if (typeof item === 'object' && item !== null) {
          extractFilesFromFormikValues(item, allFiles, `${currentKey}`);
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      extractFilesFromFormikValues(value, allFiles, currentKey);
    }
  });
}
