export function emailTemplate(children) {
  return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" lang="fr">
      <head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width" />
        <link href="//cdn.muicss.com/mui-0.10.3/email/mui-email-styletag.css" rel="stylesheet" />
        <link href="//cdn.muicss.com/mui-0.10.3/email/mui-email-inline.css" rel="stylesheet" />
      </head>
      <body>
        <table className="mui-body" cellPadding="0" cellSpacing="0" border="0">
          <tr>
            <td>
              ${children}
              <p>
                AUTONOOM - https://autonoom.fr -  7T RUE Soubzmain 44000 Nantes
              </p>
            </td>
          </tr>
        </table>
      </body>
    </html>
  `;
}

export default emailTemplate;
