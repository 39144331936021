import React from 'react';
import {
  Button,
  Typography,
  Paper,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { logEvent } from 'firebase/analytics';
import googleLogo from '../../files/googleLogo.png';
import AcceptGTCU from './AcceptGTCU';

export default function SocialSigninCard(props) {
  const {
    afterSignInRedirect, t, analytics, navigate, Link, gtcuCookieName, ourSignInWithPopup, signinCallback = () => {},
  } = props;
  const cookies = new Cookies();
  const [acceptedGTCU, setacceptedGTCU] = React.useState(false);
  const [showGTCUError, setshowGTCUError] = React.useState(false);

  const signIn = async () => {
    if (!acceptedGTCU) {
      return setshowGTCUError(true);
    }
    localStorage.setItem(gtcuCookieName, 'true');
    cookies.set(gtcuCookieName, 'true');
    try {
      const result = await ourSignInWithPopup();
      console.log(signinCallback);
      signinCallback();
      if (afterSignInRedirect) {
        navigate(afterSignInRedirect);
      }
      return result;
    } catch (error) {
      logEvent(analytics, 'login_error', {
        appName: 'Autonoom Web',
        error_message: error.message,
      });
    }
    return null;
  };
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
        gap: 1,
        width: '100%',
        maxWidth: 670,
      }}
    >
      <Typography component="span" variant="subtitle1" gutterBottom>
        {t('signin.google_login')}
      </Typography>
      <AcceptGTCU
        acceptedGTCU={acceptedGTCU}
        setacceptedGTCU={setacceptedGTCU}
        showGTCUError={showGTCUError}
        setshowGTCUError={setshowGTCUError}
        t={t}
        Link={Link}
      />
      <Button
        variant="contained"
        color="inherit"
        onClick={signIn}
      >
        <img style={{ width: 20, marginRight: 5 }} src={googleLogo} alt="Google Autonoom" />
        {t('signin.login')}
      </Button>
    </Paper>
  );
}
