export const setCookiesAccepted = (store, boolean) => {
  store.setState({ cookiesAccepted: boolean });
};

export const setSnackbarMessage = (store, options = { message: '', severiy: 'info', displayDuration: 10000 }) => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity,
      displayDuration: options.displayDuration,
    },
  });
};

export const closeSnackbar = (store) => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserRoles = (store, anArray) => {
  store.setState({ roles: anArray || [] });
};

export const setUserStatus = (store, status) => {
  store.setState({ userStatus: status });
};
