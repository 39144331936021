// eslint-disable-next-line import/prefer-default-export
export const minimalInitialValues = {
  vehicle: {
    licenseStatus: 'withDrivingLicence',
    brand: 'RENAULT',
    model: '408 Break',
    registrationNumber: 'DD-333-DD',
    registrationDate: '2024-03-14T20:00:00.000Z',
    VIN: '123456',
    fiscalPower: 22,
    fuelType: 'Essence',
    gearbox: 'manual',
    doorCount: '5',
    technicalInformation: '',
    registrationCertificate: null,
    state: {
      stateInforGivenCheck: true,
      mileage: 32996,
      lastTechnicalCheck: null,
      technicalValidityDate: null,
      technicalCheckCertificate: null,
      vehicleConditionDescription: '',
      generalConditionNotes: '',
      hadAccidents: false,
      accidentHistory: [],
    },
    photos: [
    ],
    maintenance: {
      hasMaintenanceBook: false,
      maintenanceBookDigital: null,
      maintenanceBookPhysical: false,
      maintenanceAndRepairInvoices: [],
      invoicesPhysical: false,
      allInfoGivenCheck: true,
    },
    goodFaithCheck: true,
  },
  vehicleSaleDetails: {
    price: 12000,
    paymentMethod: 'chèque',
    deliveryDateRange: {
      start: '2024-03-03T20:00:00.000Z',
      end: '2024-03-26T20:00:00.000Z',
    },
    deliveryLocation: {
      address: '7 Hent Kermao',
      postalCode: '29950',
      city: 'Gouesnach',
      deliveryComments: '',
    },
    additionalInfo: '',
  },
  seller: {
    sellerType: 'individual',
    individual: {
      lastName: 'Cancion',
      firstName: 'Pedro',
      birthDate: '2024-02-06T20:00:00.000Z',
      birthPlace: 'St Pedro',
      nationality: 'Spanglish',
      address: '62 Calle Carmen',
      postalCode: '8330452',
      city: 'Santiago',
      identityProof: null,
      ownerDeclaration: true,
    },
    entity: {
      companyType: '',
      companyName: '',
      corporateCapital: '',
      sirenNumber: '',
      address: '',
      postalCode: '',
      city: '',
      representativeLastName: '',
      representativeFirstName: '',
      representativeBirthDate: null,
      representativeBirthPlace: '',
      representativeNationality: '',
      position: '',
      kbisProof: null,
      identityProof: null,
      companyDeclaration: false,
    },
    nonProfessionalSellerCertification: true,
  },
};
