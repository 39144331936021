import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import StoragePDFViewer from 'components/StoragePDFViewer';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useMenu from 'menu-actions/useMenu';

export default function ContractPDFPage() {
  const location = useLocation();
  const contract = location.state?.contract;
  const navigate = useNavigate();
  const { setreturnAction } = useMenu();

  useEffect(() => {
    setreturnAction(() => () => navigate('/myContracts'));
    if (!contract) {
      navigate('/');
    }
  }, [navigate, contract, setreturnAction]);

  if (!contract) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ContractPDFPageContent
      contract={location.state.contract}
      storageDocPath={location.state.storageDocPath}
    />
  );
}

function ContractPDFPageContent({ contract, storageDocPath }) {
  const { t } = useTranslation();
  const [sendCertifDialogOpen, setsendCertifDialogOpen] = useState(false);
  const [docBlob, setdocBlob] = useState(undefined);

  const downloadPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = blobUrl;
    a.target = '_blank';
    a.download = `autonoom-contrat-${contract.ownerForm.values.vehicle.model}.pdf`;
    a.click();
    a.remove();
    // Added this line to revoke the blob URL after its usage
    URL.revokeObjectURL(blobUrl);
  }, [contract]);

  const printPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;

    iframe.onload = () => {
      setTimeout(() => {
        try {
          // Try to call the print function
          iframe.contentWindow.print();
        } catch (error) {
          // If print function is blocked, display an error
          console.error('Print function failed.', error);
        }
      }, 0);
    };

    // Append the iframe to the body
    document.body.appendChild(iframe);
  }, []);

  const handleOpenSendCertificate = useCallback((url, blob) => {
    setdocBlob(blob);
    setsendCertifDialogOpen(true);
  }, []);

  const actions = useMemo(
    () => [
      {
        name: t('download'),
        action: downloadPdf,
        icon: <DownloadIcon />,
      },
      {
        name: t('print'),
        action: printPdf,
        icon: <PrintIcon />,
      },
      {
        name: t('send_email'),
        action: handleOpenSendCertificate,
        icon: <AttachEmailIcon />,
      },
    ],
    [downloadPdf, handleOpenSendCertificate, printPdf, t],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">
        {t('result_contract_pdf.success')}
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {t('result_contract_pdf.info')}
      </Typography>
      <StoragePDFViewer
        path={storageDocPath}
        actionButtons={actions}
      />
      {/* <SendCertificateDialog
        open={sendCertifDialogOpen}
        setOpen={setsendCertifDialogOpen}
        docBlob={docBlob}
        docName={operationId}
      /> */}
    </Box>
  );
}
