import React from 'react';
import {
  Box, Fab, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapMenu from '@mui/icons-material/FormatListNumbered';

export default function FormMap({
  mapOpen, setMapOpen, scrollToRef, vehicleSection,
  vehicleSection2, vehicleSection3, vehicleSaleDetailsSection,
  sellerSection, isSmallScreen, activeSection,
}) {
  const { t } = useTranslation();
  const toggleMap = (event) => {
    setMapOpen(!mapOpen);
  };

  const sectionStyles = (sectionName) => ({
    cursor: 'pointer',
    fontSize: sectionName === activeSection ? '14px' : '10px',
    fontWeight: sectionName === activeSection ? 'bold' : 'normal',
    color: sectionName === activeSection ? '#f5f5f7' : '#FFFFFF',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  });

  return (
    <Box>
      {isSmallScreen && (
        <Fab
          color="primary"
          aria-label="map"
          onClick={toggleMap}
          size="small"
          style={{
            position: 'fixed', top: 75, left: 16, zIndex: 1050, backgroundColor: 'rgba(75, 75, 75, 0.9)',
          }}
        >
          <MapMenu />
        </Fab>
      )}
      {Boolean(mapOpen) && (
        <Box
          sx={{
            position: 'fixed',
            top: 120,
            left: 16,
            zIndex: 1049,
            backgroundColor: 'rgba(75, 75, 75, 0.9)',
            color: 'white',
            maxWidth: 'calc(100% - 32px)',
            borderRadius: '4px',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
            padding: 3,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
          >
            <Typography
              sx={sectionStyles('vehicleSection')}
              onClick={() => scrollToRef(vehicleSection)}
            >
              {`1. ${t('saleForm.vehicle.title')}`}
            </Typography>
            <Typography
              sx={{ ...sectionStyles('vehicleSection'), ml: 2 }}
              onClick={() => scrollToRef(vehicleSection)}
            >
              {`1.1 ${t('saleForm.vehicle.title')}`}
            </Typography>
            <Typography
              sx={{ ...sectionStyles('vehicleSection2'), ml: 2 }}
              onClick={() => scrollToRef(vehicleSection2)}
            >
              {`1.2 ${t('saleForm.vehicle.maintenance.title')}`}
            </Typography>
            <Typography
              sx={{ ...sectionStyles('vehicleSection3'), ml: 2 }}
              onClick={() => scrollToRef(vehicleSection3)}
            >
              {`1.3 ${t('saleForm.vehicle.maintenance.title')}`}
            </Typography>
            <Typography
              sx={sectionStyles('vehicleSaleDetailsSection')}
              onClick={() => scrollToRef(vehicleSaleDetailsSection)}
            >
              {`2. ${t('saleForm.vehicleSaleDetails.title')}`}
            </Typography>
            <Typography
              sx={sectionStyles('sellerSection')}
              onClick={() => scrollToRef(sellerSection)}
            >
              {`3. ${t('saleForm.seller.title')}`}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
