import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const validationSchema = (t) => Yup.object().shape({
  contractConcludedAccordingToTheFrenchLaw: Yup.boolean().oneOf([true], t('required')),
  keys: Yup.boolean().oneOf([true], t('required')),
  registrationCertificate: Yup.boolean().oneOf([true], t('required')),
  technicalControlReport: Yup.boolean().oneOf([true], t('required')),
  pledgeCertificateDocument: Yup.mixed().required(t('required')),
  europeanConformityCertificate: Yup.mixed().nullable(true),
  knowledgeNonPayment: Yup.boolean().oneOf([true], t('required')),
});
