import React from 'react';
import {
  Autocomplete,
  Box, InputAdornment, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeliveryLocation from './DeliveryLocation';

export default function VehicleSaleDetails({ formik, isReadOnly }) {
  const { t } = useTranslation();
  const paymentMethods = ['chèque', 'virement bancaire'];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4">{`2. ${t('saleForm.vehicleSaleDetails.title')} : `}</Typography>

      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'stretch',
      }}
      >
        <TextField
          disabled={isReadOnly}
          sx={{ flexGrow: 1 }}
          label={t('saleForm.vehicleSaleDetails.price')}
          name="vehicleSaleDetails.price"
          type="number"
          value={formik.values.vehicleSaleDetails.price}
          onChange={formik.handleChange}
          error={formik.touched.vehicleSaleDetails?.price && Boolean(formik.errors.vehicleSaleDetails?.price)}
          helperText={formik.touched.vehicleSaleDetails?.price && formik.errors.vehicleSaleDetails?.price}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />

        {/* Payment Method field */}
        <Autocomplete
          disabled={isReadOnly}
          sx={{ minWidth: { xs: '100%', sm: 400 }, flexGrow: 2 }}
          freeSolo
          options={paymentMethods}
          value={formik.values.vehicleSaleDetails.paymentMethod || ''}
          onChange={(event, value) => formik.setFieldValue('vehicleSaleDetails.paymentMethod', value)}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'clear') {
              formik.setFieldValue('vehicleSaleDetails.paymentMethod', '');
            }
          }}
          renderInput={(params) => (
            <TextField
              disabled={isReadOnly}
              label={t('saleForm.vehicleSaleDetails.paymentMethod')}
              onBlur={() => formik.setFieldValue('vehicleSaleDetails.paymentMethod', params.inputProps.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  formik.setFieldValue('vehicleSaleDetails.paymentMethod', params.inputProps.value);
                }
              }}
              error={formik.touched.vehicleSaleDetails?.paymentMethod
                && Boolean(formik.errors.vehicleSaleDetails?.paymentMethod)}
              helperText={formik.touched.vehicleSaleDetails?.paymentMethod
                && formik.errors.vehicleSaleDetails?.paymentMethod}
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
      </Box>

      {/* Delivery Date Range field */}
      <Typography>{`${t('saleForm.vehicleSaleDetails.deliveryDateRange.title')} : `}</Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('saleForm.vehicleSaleDetails.deliveryDateRange.start')}
            format="DD/MM/YYYY"
            value={formik.values.vehicleSaleDetails.deliveryDateRange.start}
            onChange={(date) => {
              formik.setFieldValue('vehicleSaleDetails.deliveryDateRange.start', date);
            }}
            slotProps={{
              textField: {
                name: 'vehicleSaleDetails.deliveryDateRange.start',
                error: Boolean(formik.touched.vehicleSaleDetails?.deliveryDateRange?.start
                && formik.errors.vehicleSaleDetails?.deliveryDateRange?.start),
                helperText: formik.touched.vehicleSaleDetails?.deliveryDateRange?.start
              && formik.errors.vehicleSaleDetails?.deliveryDateRange?.start,
                onBlur: () => formik.setFieldTouched('vehicleSaleDetails.deliveryDateRange.start', true),
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('saleForm.vehicleSaleDetails.deliveryDateRange.end')}
            format="DD/MM/YYYY"
            value={formik.values.vehicleSaleDetails.deliveryDateRange.end}
            onChange={(date) => {
              formik.setFieldValue('vehicleSaleDetails.deliveryDateRange.end', date);
            }}
            slotProps={{
              textField: {
                name: 'vehicleSaleDetails.deliveryDateRange.end',
                error: Boolean(formik.touched.vehicleSaleDetails?.deliveryDateRange?.end
                && formik.errors.vehicleSaleDetails?.deliveryDateRange?.end),
                helperText: formik.touched.vehicleSaleDetails?.deliveryDateRange?.end
              && formik.errors.vehicleSaleDetails?.deliveryDateRange?.end,
                onBlur: () => formik.setFieldTouched('vehicleSaleDetails.deliveryDateRange.end', true),
              },
            }}
          />
        </LocalizationProvider>

      </Box>

      <DeliveryLocation formik={formik} isReadOnly={isReadOnly} />
    </Box>
  );
}
