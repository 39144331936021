import React from 'react';
import {
  Box, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DeliveryLocation({ formik, isReadOnly }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicleSaleDetails.deliveryLocation.address')}
        name="vehicleSaleDetails.deliveryLocation.address"
        value={formik.values.vehicleSaleDetails.deliveryLocation.address}
        onChange={formik.handleChange}
        error={formik.touched.vehicleSaleDetails?.deliveryLocation?.address
            && Boolean(formik.errors.vehicleSaleDetails?.deliveryLocation?.address)}
        helperText={formik.touched.vehicleSaleDetails?.deliveryLocation?.address
            && formik.errors.vehicleSaleDetails?.deliveryLocation?.address}
      />

      {/* Postal Code field */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicleSaleDetails.deliveryLocation.postalCode')}
        name="vehicleSaleDetails.deliveryLocation.postalCode"
        value={formik.values.vehicleSaleDetails.deliveryLocation.postalCode}
        onChange={formik.handleChange}
        error={formik.touched.vehicleSaleDetails?.deliveryLocation?.postalCode
             && Boolean(formik.errors.vehicleSaleDetails?.deliveryLocation?.postalCode)}
        helperText={formik.touched.vehicleSaleDetails?.deliveryLocation?.postalCode
            && formik.errors.vehicleSaleDetails?.deliveryLocation?.postalCode}
      />

      {/* City field */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicleSaleDetails.deliveryLocation.city')}
        name="vehicleSaleDetails.deliveryLocation.city"
        value={formik.values.vehicleSaleDetails.deliveryLocation.city}
        onChange={(event) => {
          const value = event.target.value.toUpperCase();
          formik.setFieldValue('vehicleSaleDetails.deliveryLocation.city', value);
        }}
        error={formik.touched.vehicleSaleDetails?.deliveryLocation?.city
            && Boolean(formik.errors.vehicleSaleDetails?.deliveryLocation?.city)}
        helperText={formik.touched.vehicleSaleDetails?.deliveryLocation?.city
            && formik.errors.vehicleSaleDetails?.deliveryLocation?.city}
      />

      {/* Delivery Comments field */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicleSaleDetails.deliveryLocation.deliveryComments')}
        name="vehicleSaleDetails.deliveryLocation.deliveryComments"
        multiline
        rows={4}
        value={formik.values.vehicleSaleDetails.deliveryLocation.deliveryComments}
        onChange={formik.handleChange}
      />
    </Box>
  );
}
