import React from 'react';
import {
  Box, Button, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { v4 as uuidv4 } from 'uuid';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';

export default function VehicleState({ formik, isReadOnly }) {
  const { t } = useTranslation();

  const handleAddAccident = () => {
    const newAccident = {
      id: uuidv4(),
      accidentDate: null,
      accidentDescription: '',
      repairDocuments: null,
    };
    formik.setFieldValue('vehicle.state.accidentHistory', [
      ...formik.values.vehicle.state.accidentHistory,
      newAccident,
    ]);
  };

  const handleRemoveAccident = (index) => {
    const updatedAccidents = [...formik.values.vehicle.state.accidentHistory];
    updatedAccidents.splice(index, 1);
    formik.setFieldValue('vehicle.state.accidentHistory', updatedAccidents);
  };

  const hiddenDefectsInfo = t('hidden_deffects_info', { returnObjects: true });

  return (
    <Box
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="h6">{`1.2 ${t('saleForm.vehicle.state.title')} : `}</Typography>
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={(
          <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
            {hiddenDefectsInfo.map((line, index) => (
              <div key={line}>
                {[0, 4].includes(index) ? <strong>{line}</strong> : line}
              </div>
            ))}
          </Typography>
        )}
        input={(
          <AgreeCheckMark
            isReadOnly={isReadOnly}
            formik={formik}
            field="vehicle.state.stateInforGivenCheck"
            label={t('saleForm.vehicle.state.stateInforGivenCheck')}
            extraText={t('saleForm.vehicle.state.stateInforGivenCheckExtraText')}
          />
      )}
      />
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicle.state.mileage')}
        name="vehicle.state.mileage"
        value={formik.values.vehicle.state.mileage}
        onChange={formik.handleChange}
        type="number"
        error={formik.touched.vehicle?.state?.mileage && Boolean(formik.errors.vehicle?.state?.mileage)}
        helperText={formik.touched.vehicle?.state?.mileage && formik.errors.vehicle?.state?.mileage}
      />

      {/* Last technical check field */}
      <LocalizationProvider
        dateAdapter={AdapterMoment}
      >
        <DatePicker
          disabled={isReadOnly}
          sx={{ width: '100%' }}
          label={t('saleForm.vehicle.state.lastTechnicalCheck')}
          format="DD/MM/YYYY"
          value={formik.values.vehicle.state.lastTechnicalCheck}
          onChange={(date) => {
            formik.setFieldValue('vehicle.state.lastTechnicalCheck', date);
          }}
          slotProps={{
            textField: {
              name: 'vehicle.state.lastTechnicalCheck',
              error: Boolean(formik.touched.vehicle?.state?.lastTechnicalCheck
                && formik.errors.vehicle?.state?.lastTechnicalCheck),
              helperText: formik.touched.vehicle?.state?.lastTechnicalCheck
              && formik.errors.vehicle?.state?.lastTechnicalCheck,
              onBlur: () => formik.setFieldTouched('vehicle.state.lastTechnicalCheck', true),
            },
          }}
        />
      </LocalizationProvider>

      {/* Technical validity date field */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isReadOnly}
          sx={{ width: '100%' }}
          label={t('saleForm.vehicle.state.technicalValidityDate')}
          format="DD/MM/YYYY"
          value={formik.values.vehicle.state.technicalValidityDate}
          onChange={(date) => {
            formik.setFieldValue('vehicle.state.technicalValidityDate', date);
          }}
          slotProps={{
            textField: {
              name: 'vehicle.state.technicalValidityDate',
              error: Boolean(formik.touched.vehicle?.state?.technicalValidityDate
                && formik.errors.vehicle?.state?.technicalValidityDate),
              helperText: formik.touched.vehicle?.state?.technicalValidityDate
              && formik.errors.vehicle?.state?.technicalValidityDate,
              onBlur: () => formik.setFieldTouched('vehicle.state.technicalValidityDate', true),
            },
          }}
        />
      </LocalizationProvider>

      {/* Technical check certificate field (File input example) */}
      <InputFileUpload
        isReadOnly={isReadOnly}
        fileBaseName={t('fileName.technicalCheck')}
        formik={formik}
        field="vehicle.state.technicalCheckCertificate"
        label={`${t('saleForm.vehicle.state.technicalCheckCertificate')} : `}
        accept="image/jpeg,image/png,application/pdf"
      />
      {/* Vehicle condition description */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicle.state.vehicleConditionDescription')}
        name="vehicle.state.vehicleConditionDescription"
        multiline
        rows={4}
        value={formik.values.vehicle.state.vehicleConditionDescription}
        onChange={formik.handleChange}
      />

      {/* General condition notes */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicle.state.generalConditionNotes')}
        name="vehicle.state.generalConditionNotes"
        multiline
        rows={4}
        value={formik.values.vehicle.state.generalConditionNotes}
        onChange={formik.handleChange}
      />

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography>{`${t('saleForm.vehicle.state.hadAccidents')}`}</Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.state.hadAccidents}
          exclusive
          onChange={(event, newAlignment) => {
            formik.setFieldValue('vehicle.state.hadAccidents', newAlignment);
          }}
          aria-label="saleForm.vehicle.state.hadAccidents"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'stretch',
      }}
      >
        {formik.values.vehicle.state.accidentHistory?.map((accident, index) => (
          <Box
            key={accident.id}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 1,
              alignItems: 'stretch',
              backgroundColor: 'lightGray.main',
              p: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                flexGrow: 9,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={isReadOnly}
                  sx={{ width: '100%' }}
                  label={t('saleForm.vehicle.state.accidentHistory.accidentDate')}
                  format="DD/MM/YYYY"
                  value={accident.accidentDate}
                  onChange={(date) => {
                    formik.setFieldValue(`vehicle.state.accidentHistory[${index}].accidentDate`, date);
                  }}
                  slotProps={{
                    textField: {
                      name: `vehicle.state.accidentHistory[${index}].accidentDate`,
                      error: Boolean(
                        formik.touched.vehicle?.state?.accidentHistory?.[index]?.accidentDate
                          && formik.errors.vehicle?.state?.accidentHistory?.[index]?.accidentDate,
                      ),
                      helperText: formik.touched.vehicle?.state?.accidentHistory?.[index]?.accidentDate
                      && formik.errors.vehicle?.state?.accidentHistory?.[index]?.accidentDate,
                      onBlur: () => formik.setFieldTouched(
                        `vehicle.state.accidentHistory[${index}].accidentDate`,
                        true,
                      ),
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                disabled={isReadOnly}
                label={t('saleForm.vehicle.state.accidentHistory.accidentDescription')}
                name={`vehicle.state.accidentHistory[${index}].accidentDescription`}
                multiline
                rows={4}
                value={accident.accidentDescription}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.vehicle?.state?.accidentHistory?.[index]?.accidentDescription
                  && formik.errors.vehicle?.state?.accidentHistory?.[index]?.accidentDescription)}
                helperText={formik.touched.vehicle?.state?.accidentHistory?.[index]?.accidentDescription
                  && formik.errors.vehicle?.state?.accidentHistory?.[index]?.accidentDescription}
              />
              <InputFileUpload
                isReadOnly={isReadOnly}
                fileBaseName="accident-image"
                formik={formik}
                field={`vehicle.state.accidentHistory[${index}].repairDocuments`}
                label={`${t('saleForm.vehicle.state.accidentHistory.repairDocuments')} : `}
                accept="image/jpeg,image/png,application/pdf"
                maxFiles={3}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
              }}
            >
              <IconButton size="large" onClick={() => handleRemoveAccident(index)}>
                <RemoveCircleIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!isReadOnly && (
          <Button type="button" onClick={handleAddAccident} variant="outlined" startIcon={<AddCircleIcon />}>
            {t('saleForm.vehicle.state.addAccident')}
          </Button>
        )}
      </Box>

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message="image"
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t('fileName.sellerVehiclePhoto')}
            formik={formik}
            field="vehicle.photos"
            label={`${t('saleForm.vehicle.photos')} : `}
            accept="image/jpeg,image/png"
            maxFiles={5}
          />
          )}
      />

      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="vehicle.goodFaithCheck"
        label={t('saleForm.vehicle.goodFaithCheck')}
      />

    </Box>
  );
}
