import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import autonoom from 'files/logo_ligne_pour_fond_clair.svg';
import { Link } from 'react-router-dom';
// import MailIcon from '@mui/icons-material/Mail';
import VehicleSaleIcon from 'icons/VehicleSaleIcon';
import FolderIcon from '@mui/icons-material/Folder';

function Welcome() {
  const { t } = useTranslation();

  // useEffect(() => {
  //   document.body.style.backgroundColor = '#00CAC1';
  //   return () => {
  //     document.body.style.backgroundColor = null;
  //   };
  // }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Box sx={{
        width: { xs: '80%', md: 500 }, textAlign: 'center', mb: { xs: 2, md: 10 }, mt: 5,
      }}
      >
        <img style={{ width: '100%', maxWidth: 800, height: 'auto' }} src={autonoom} alt="Autonoom Logo" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          px: 1,
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          sx={{
            flexGrow: 1,
            width: { xs: '100%', md: 400 },
            height: { xs: 200, md: 300 },
            gap: 3,
            display: 'flex',
            flexDirection: 'column',
            background: 'linear-gradient(45deg, #3db87f 30%, #4fde9c 90%)',
            '&.Mui-disabled': {
              background: '#929292',
            },
          }}
          component={Link}
          to="/vehicleSale"
        >
          <Box
            sx={{
              borderRadius: '50%',
              width: { xs: 75, md: 95 },
              height: { xs: 75, md: 95 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: { xs: 2, md: 2.5 },
              backgroundColor: 'secondary.main',
            }}
          >
            <VehicleSaleIcon size={150} color="#FFFFFF" />
          </Box>
          {t('vehicle_sale_contract')}
        </Button>
        <Button
          variant="contained"
          sx={{
            flexGrow: 1,
            width: { xs: '100%', md: 400 },
            height: { xs: 200, md: 300 },
            gap: 3,
            display: 'flex',
            flexDirection: 'column',
            background: 'linear-gradient(45deg, #109993 30%, #14c7bf 90%)',
            '&:hover': {
              backgroundPosition: 'right center',
              color: '#fff',
              textDecoration: 'none',
            },
          }}
          component={Link}
          to="/myContracts"
        >
          <Box
            sx={{
              borderRadius: '50%',
              width: { xs: 75, md: 95 },
              height: { xs: 75, md: 95 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#14c7bf',
            }}
          >
            <FolderIcon sx={{ fontSize: 48, color: 'white' }} />
          </Box>
          {t('my_contracts')}
        </Button>
      </Box>
      {/* <Button
        variant="contained"
        size="large"
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', sm: 300 },
          background: 'linear-gradient(45deg, #09524e 30%, #002b34 90%)',
        }}
        component={Link}
        to="/contact"
      >
        <Box
          sx={{
            borderRadius: '50%',
            width: 75,
            height: 75,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#109993',
          }}
        >
          <MailIcon sx={{ fontSize: 48, color: 'white' }} />
        </Box>
        {t('contact.contact_us')}
      </Button> */}
    </Box>
  );
}

export default Welcome;
