// eslint-disable-next-line import/prefer-default-export
export function resizeImage(file, maxWidth, maxHeight, maxFileSize) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      let quality = 0.9; // Start with high quality
      const reduceQualityAndResolve = (canvas) => {
        canvas.toBlob(
          (blob) => {
            if (blob.size > maxFileSize && quality > 0.1) {
              quality -= 0.1; // Reduce quality by 10%
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              reduceQualityAndResolve(canvas); // Recurse
            } else {
              resolve(new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }));
            }
          },
          'image/jpeg',
          quality,
        );
      };

      let { width } = img;
      let { height } = img;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Scale dimensions if necessary to fit the max width/height
      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height *= maxWidth / width;
          width = maxWidth;
        } else {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      reduceQualityAndResolve(canvas);
    };
    img.onerror = (error) => reject(error);
  });
}
