import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';

export default function SaleFormalities({ formik, isReadOnly }) {
  const { t } = useTranslation();

  const hiddenWarrantyText = t(
    'buyerForm.saleFormalities.knowledgeNonApplicationWarrantyHiddenDefectsInfo',
    { returnObjects: true },
  );

  const insuranceText = t(
    'buyerForm.saleFormalities.knowledgeVehicleInsuranceInfo',
    { returnObjects: true },
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
    }}
    >
      <Typography sx={{ textAlign: 'left', mt: 3 }}>
        {insuranceText.map((line, index) => (
          <span key={line}>
            {[0].includes(index) ? <strong>{line}</strong> : line}
          </span>
        ))}
      </Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="saleFormalities.knowledgeVehicleInsurance"
        label={t('buyerForm.saleFormalities.knowledgeVehicleInsurance')}
        extraText={t('buyerForm.saleFormalities.knowledgeVehicleInsuranceExtra')}
      />
      <Typography sx={{ textAlign: 'left', mt: 3 }}>
        {hiddenWarrantyText.map((line, index) => (
          <span key={line}>
            {[0].includes(index) ? <strong>{line}</strong> : line}
          </span>
        ))}
      </Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="saleFormalities.knowledgeNonApplicationWarrantyHiddenDefects"
        label={t('buyerForm.saleFormalities.knowledgeNonApplicationWarrantyHiddenDefects')}
        extraText={t('buyerForm.saleFormalities.knowledgeNonApplicationWarrantyHiddenDefectsExtra')}
      />
    </Box>
  );
}
