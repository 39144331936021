import { useState, useEffect } from 'react';
import moment from 'moment';

export const serializeFormValues = (values) => {
  if (Array.isArray(values)) {
    return values.map((item) => serializeFormValues(item));
  } if (values instanceof moment) {
    return values.toISOString();
  } if (values instanceof File) {
    return undefined; // Omit File objects
  } if (typeof values === 'object' && values !== null) {
    const serializedValues = {};
    Object.keys(values).forEach((key) => {
      serializedValues[key] = serializeFormValues(values[key]);
    });
    return serializedValues;
  }
  return values;
};

export const deserializeFormValues = (values) => {
  if (Array.isArray(values)) {
    return values.map((item) => deserializeFormValues(item)).filter((item) => item !== null);
  } if (typeof values === 'string' && moment(values, moment.ISO_8601, true).isValid()) {
    return moment(values);
  } if (typeof values === 'object' && values !== null) {
    const deserializedValues = {};
    Object.keys(values).forEach((key) => {
      deserializedValues[key] = deserializeFormValues(values[key]);
    });
    return deserializedValues;
  }
  return values;
};

export const usePersistedFormikState = (formikInitialValues, key) => {
  const [savedValues, setSavedValues] = useState(() => {
    const savedData = sessionStorage.getItem(key);
    const dataReturned = savedData ? deserializeFormValues(JSON.parse(savedData))
      : deserializeFormValues(formikInitialValues);
    return dataReturned;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(serializeFormValues(savedValues)));
  }, [savedValues, key]);

  return [savedValues, setSavedValues];
};
