import { PDFDocument, rgb, degrees } from 'pdf-lib';

export async function addWatermarkToPdf(file) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    const pages = pdfDoc.getPages();

    pages.forEach((page, pageIndex) => {
      const { width, height } = page.getSize();
      const fontSize = 15;

      const titleWatermarkText = `${file.name}, page ${pageIndex + 1}`;
      const titleWatermarkFontSize = 10;
      const titleWatermarkX = 5;
      const titleWatermarkY = page.getSize().height - 25;

      page.drawText(titleWatermarkText, {
        x: titleWatermarkX,
        y: titleWatermarkY,
        size: titleWatermarkFontSize,
        color: rgb(0.15, 0.15, 0.15),
        opacity: 1,
      });

      const watermark = 'Document destiné uniquement à la vente entre particuliers.';

      const textLength = watermark.length * (fontSize * 0.45);
      // const textLength = 1000;
      const diagonalLength = Math.sqrt(width * width + height * height);
      const repetitionsPerLine = Math.ceil(diagonalLength / textLength);

      const angleInRadians = Math.atan2(height, width);
      const angleInDegrees = angleInRadians * (180 / Math.PI);
      const lines = Math.ceil(diagonalLength / (fontSize * 2));

      for (let line = 0; line < lines; line += 1) {
      // Adjust starting y position for each line
        let yPosition = line * fontSize * 4;
        const color = line % 2 === 0 ? rgb(0.91, 0.91, 0.91) : rgb(0.31, 0.31, 0.31);

        for (let i = 0; i < repetitionsPerLine; i += 1) {
        // Adjust x position for each repetition to align diagonally
          let xPosition = i * textLength;

          // Adjust x and y using line number for diagonal effect
          // This simplistic approach adds a basic diagonal positioning
          xPosition -= line * (fontSize * 2);
          yPosition += line * (fontSize / 2);

          page.drawText(watermark, {
            x: xPosition,
            y: height - yPosition, // Adjust based on height to start from the top
            size: fontSize,
            color,
            opacity: 0.5,
            rotate: degrees(-angleInDegrees),
          });
        }
      }
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const watermarkedFile = new File([blob], file.name, {
      type: 'application/pdf',
      lastModified: Date.now(),
    });

    return watermarkedFile;
  } catch (error) {
    console.error('Error adding watermark to PDF:', error);
    throw error;
  }
}

export const addWatermarkToImage = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Set watermark text properties
      const watermark = 'Document destiné uniquement à la vente entre particuliers.';
      ctx.font = '20px Arial';
      ctx.globalAlpha = 0.5;

      const measureText = ctx.measureText(watermark);
      const textWidth = measureText.width;
      const spacingX = textWidth + 20; // Add extra space after each watermark
      const spacingY = 60; // Vertical spacing between lines

      // Calculate the angle
      const angle = Math.atan2(canvas.height, canvas.width);

      // Calculate required number of lines to cover the image diagonally
      const lines = Math.ceil(Math.sqrt(canvas.width * canvas.width + canvas.height * canvas.height) / spacingY);

      // Draw watermark lines
      for (let line = 0; line < lines; line += 1) {
        ctx.fillStyle = line % 2 === 0 ? 'rgba(255, 255, 255, 0.75)' : 'rgba(117, 117, 117, 0.75)'; // Alternate color
        for (let x = -canvas.width; x < canvas.width + canvas.height; x += spacingX) {
          ctx.save();
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(-angle);
          ctx.fillText(watermark, x, line * spacingY - lines * spacingY / 2);
          ctx.restore();
        }
      }

      canvas.toBlob((blob) => {
        const watermarkedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
        resolve(watermarkedFile);
      }, file.type);
    };
    img.src = event.target.result;
  };
  reader.onerror = (error) => reject(error);
  reader.readAsDataURL(file);
});
