import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import {
  Box, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';

export default function SharedSaleFormEndDialog({
  continueDialogOpen,
  setContinueDialogOpen, handleSubmit,
  pageStatus, buyerEmail, setBuyerEmail,
  bothPartiesPresent, setBothPartiesPresent,
}) {
  const { t } = useTranslation();
  return (
    <Dialog open={continueDialogOpen} onClose={() => setContinueDialogOpen(false)}>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Box sx={{
          display: 'flex',
          gap: 3,
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
        >
          <Typography sx={{ maxWidth: 300, fontWeight: 'bold' }}>{t('both_parties.are_present')}</Typography>
          <ToggleButtonGroup
            color="primary"
            value={bothPartiesPresent}
            exclusive
            onChange={(event, newAlignment) => {
              setBothPartiesPresent(newAlignment);
            }}
            aria-label="bothPartiesPresent"
            size="small"
          >
            <ToggleButton value>{t('yes')}</ToggleButton>
            <ToggleButton value={false}>{t('no')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {bothPartiesPresent ? (
          <Typography>
            {t('both_parties.yes')}
          </Typography>
        )
          : (
            <Typography>
              {t('both_parties.no')}
            </Typography>
          )}
        <TextField
          autoFocus
          label={t('email')}
          type="email"
          fullWidth
          value={buyerEmail || ''}
          onChange={(event) => setBuyerEmail(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={pageStatus === 'loading'}
          color="primary"
          disabled={buyerEmail === ''}
          onClick={handleSubmit}
        >
          {t('continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
