import {
  Button,
  Dialog, DialogActions, DialogContent, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AcquirerPresentDialog({
  open, setOpen,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.h6.fontSize,
            },
            maxWidth: 800,
            m: { xs: 1, sm: 3, md: 6 },
            whiteSpace: 'pre-line',
          }}
        >
          {t('acquirer_present')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="large" onClick={handleClose}>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AcquirerPresentDialog;
