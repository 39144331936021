import * as Yup from 'yup';

const accidentSchema = (t) => Yup.object().shape({
  accidentDate: Yup.date().required(t('required')),
  accidentDescription: Yup.string().required(t('required')),
  repairDocuments: Yup.mixed().nullable(true),
});

const photoOrFileValidation = (value) => {
  if (!value.length) return false; // Must have at least one file
  return value.every((file) => /\.(jpe?g|png|pdf|docx)$/i.test(file.name));
};

const vehicleSchema = (t) => Yup.object().shape({
  licenseStatus: Yup.string().required(t('required')),
  brand: Yup.string().required(t('required')),
  model: Yup.string().required(t('required')),
  registrationNumber: Yup.string().required(t('required')),
  registrationDate: Yup.date().required(t('required')),
  VIN: Yup.string().required(t('required')),
  fiscalPower: Yup.number().required(t('required')),
  fuelType: Yup.string().required(t('required')),
  gearbox: Yup.string().required(t('required')),
  doorCount: Yup.string().required(t('required')),
  technicalInformation: Yup.string().notRequired(),
  registrationCertificate: Yup.array()
    .test('is-valid-photo', t('required'), photoOrFileValidation)
    .required(t('required')),
  state: Yup.object().shape({
    stateInforGivenCheck: Yup.boolean().oneOf([true], t('required')),
    mileage: Yup.number().required(t('required')),
    lastTechnicalCheck: Yup.date().notRequired(),
    technicalValidityDate: Yup.date().notRequired(),
    technicalCheckCertificate: Yup.mixed().nullable(true),
    vehicleConditionDescription: Yup.string().notRequired(),
    generalConditionNotes: Yup.string().notRequired(),
    accidentHistory: Yup.array().of(accidentSchema(t)),
  }),
  photos: Yup.array()
    .test('is-valid-photo', t('required'), photoOrFileValidation)
    .required(t('required')),
  maintenance: Yup.object().shape({
    hasMaintenanceBook: Yup.boolean().required(t('required')),
    maintenanceBookDigital: Yup.mixed().nullable(true),
    maintenanceBookPhysical: Yup.boolean().required(t('required')),
    maintenanceAndRepairInvoices: Yup.mixed().nullable(true),
    invoicesPhysical: Yup.boolean().required(t('required')),
    allInfoGivenCheck: Yup.boolean().oneOf([true], t('required')),
  }),
  goodFaithCheck: Yup.boolean().oneOf([true], t('required')),
});

const deliveryLocationSchema = (t) => Yup.object().shape({
  address: Yup.string().required(t('required')),
  postalCode: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  deliveryComments: Yup.string().notRequired(),
});

const vehicleSaleDetailsSchema = (t) => Yup.object().shape({
  price: Yup.number().required(t('required')),
  paymentMethod: Yup.string().required(t('required')),
  deliveryDateRange: Yup.object().shape({
    start: Yup.date().required(t('required')),
    end: Yup.date().required(t('required')),
  }),
  deliveryLocation: deliveryLocationSchema(t),
  // declaration: Yup.boolean().oneOf([true], t('required')),
});

const individualSchema = (t) => Yup.object().shape({
  lastName: Yup.string().required(t('required')),
  firstName: Yup.string().required(t('required')),
  birthDate: Yup.date().required(t('required')),
  birthPlace: Yup.string().required(t('required')),
  nationality: Yup.string().required(t('required')),
  address: Yup.string().required(t('required')),
  postalCode: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  identityProof: Yup.array()
    .test('is-valid-photo', t('required'), photoOrFileValidation)
    .required(t('required')),
  ownerDeclaration: Yup.boolean().oneOf([true], t('required')),
});

const entitySchema = (t) => Yup.object().shape({
  companyType: Yup.string().required(t('required')),
  companyName: Yup.string().required(t('required')),
  corporateCapital: Yup.number().required(t('required')),
  sirenNumber: Yup.string().required(t('required')),
  address: Yup.string().required(t('required')),
  postalCode: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  representativeLastName: Yup.string().required(t('required')),
  representativeFirstName: Yup.string().required(t('required')),
  representativeBirthDate: Yup.date().required(t('required')),
  representativeBirthPlace: Yup.string().required(t('required')),
  representativeNationality: Yup.string().required(t('required')),
  position: Yup.string().required(t('required')),
  kbisProof: Yup.mixed().required(t('required')),
  identityProof: Yup.array()
    .test('is-valid-photo', t('required'), photoOrFileValidation)
    .required(t('required')),
  companyDeclaration: Yup.boolean().oneOf([true], t('required')),
});

const sellerSchema = (t) => Yup.object().shape({
  sellerType: Yup.string().oneOf(['individual', 'entity'], t('required'))
    .required(t('required')),
  individual: Yup.object()
    .when('sellerType', {
      is: 'individual',
      then: () => individualSchema(t),
      otherwise: () => Yup.object().notRequired(),
    }),
  entity: Yup.object()
    .when('sellerType', {
      is: 'entity',
      then: () => entitySchema(t),
      otherwise: () => Yup.object().notRequired(),
    }),
  nonProfessionalSellerCertification: Yup.boolean().oneOf([true], t('required')),
});

// eslint-disable-next-line import/prefer-default-export
export const validationSchema = (t) => Yup.object().shape({
  vehicle: vehicleSchema(t),
  vehicleSaleDetails: vehicleSaleDetailsSchema(t),
  seller: sellerSchema(t),
});
