// eslint-disable-next-line import/prefer-default-export
export const initialValues = {
  vehicle: {
    licenseStatus: 'withDrivingLicence', // with or without a permit
    brand: '', // Marque
    model: '', // Modèle
    registrationNumber: '', // Numéro d'immatriculation
    registrationDate: null, // Date de première immatriculation
    VIN: '', // Numéro d'identification (VIN)
    fiscalPower: '', // Puissance fiscale
    fuelType: '', // Carburant
    gearbox: 'manual', // Boite de vitesse
    doorCount: '', // Nombre de portes
    technicalInformation: '', // Informations techniques complémentaires
    registrationCertificate: null, // Joindre le certificat d'immatriculation
    state: {
      stateInforGivenCheck: false,
      mileage: '', // Kilométrage
      lastTechnicalCheck: null, // Dernier contrôle technique
      technicalValidityDate: null, // Valable jusqu'au
      technicalCheckCertificate: null, // Joindre la copie du dernier contrôle technique
      vehicleConditionDescription: '', // Description des défauts internes et externes
      generalConditionNotes: '', // Précisions générales sur l'état du véhicule
      hadAccidents: false,
      accidentHistory: [ // an array of accidents as follow
        // {
        //   accidentDate: null, // Date de l'accident
        //   accidentDescription: '', // Décrire l'accident et les réparations
        //   repairDocuments: null, // Joindre les documents liés audites réparations
        // }
      ],
    },
    photos: [], // An array of photo images jpeg or png
    maintenance: {
      hasMaintenanceBook: false, // Le véhicule est-il fourni avec un carnet d'entretien...
      maintenanceBookDigital: null, // Joindre le carnet d'entretien s'il existe en version numérique
      maintenanceBookPhysical: false, // Préciser si le carnet d'entretien sera fourni en version papier
      maintenanceAndRepairInvoices: [], // Joindre les factures d'entretien et de réparation
      invoicesPhysical: false, // Préciser si des factures seront fournies en version papier
      additionalInfo: '',
      allInfoGivenCheck: false,
    },
    goodFaithCheck: false,
  },
  vehicleSaleDetails: {
    price: '', // Prix fixé
    paymentMethod: '', // Choix de la modalité de paiement
    deliveryDateRange: {
      start: null,
      end: null,
    },
    deliveryLocation: {
      address: '', // ADRESSE
      postalCode: '', // CODE POSTAL
      city: '', // COMMUNE
      deliveryComments: '', // Commentaire éventuel sur le lieu de livraison
    },
    additionalInfo: '', // Souhaitez vous ajouter quelque chose?
    declaration: false, // Je déclare avoir transmis...
  },
  seller: {
    sellerType: 'individual', // 'individual' for a physical person, 'entity' for a legal entity
    individual: {
      lastName: '',
      firstName: '',
      birthDate: null,
      birthPlace: '',
      nationality: '',
      address: '',
      postalCode: '',
      city: '',
      identityProof: null, // For file uploads, initially null or an empty array
      ownerDeclaration: false, // Boolean value, default to unchecked
    },
    entity: {
      companyType: '', // SA, SAS, SASU, SARL, EURL, Association, etc.
      companyName: '',
      corporateCapital: '',
      sirenNumber: '',
      address: '',
      postalCode: '',
      city: '',
      representativeLastName: '',
      representativeFirstName: '',
      representativeBirthDate: null,
      representativeBirthPlace: '',
      representativeNationality: '',
      position: '', // President, Manager, etc.
      kbisProof: null,
      identityProof: null,
      companyDeclaration: false,
    },
    nonProfessionalSellerCertification: false,
  },
};
