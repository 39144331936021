import useGlobal from 'global-state/store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ScrollToFieldError({
  formik, scrollToErrorActive, setScrollToErrorActive, formBaseKey, orderedKeys,
}) {
  const { isValid, errors } = formik;
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();

  const isHidden = (element) => {
    const style = window.getComputedStyle(element);
    return style.display === 'none'
        || style.visibility === 'hidden'
        || style.opacity === '0'
        || element.offsetWidth === 0
        || element.offsetHeight === 0
        || element.hidden;
  };

  useEffect(() => {
    if (!scrollToErrorActive) return;
    if (isValid) return;

    setScrollToErrorActive(false);

    const fieldErrorNames = getFieldErrorNames(errors, formik.values, orderedKeys);
    if (fieldErrorNames.length <= 0) return;

    globalActions.setSnackbarMessage({
      message: t('missing_input', { input: t(`${formBaseKey}.${fieldErrorNames[0]}`) }),
      severity: 'warning',
    });

    console.log(fieldErrorNames[0]);
    let element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);

    if (element && isHidden(element)) {
      element = document.querySelector(`div[id='container_${fieldErrorNames[0]}']`);
    }

    if (!element) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [errors, formBaseKey, formik.values, globalActions, isValid, orderedKeys,
    scrollToErrorActive, setScrollToErrorActive, t]);

  return null;
}

const getFieldErrorNames = (formikErrors, formikValues, orderedKeys) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value === undefined || value === null) return;
      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });
    return result;
  };
  const sortErrors = (errors, order) => errors.sort((a, b) => {
    const aFirstPart = a.split('.')[0];
    const bFirstPart = b.split('.')[0];
    const aIndex = order.indexOf(aFirstPart);
    const bIndex = order.indexOf(bFirstPart);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });

  const firstLayerKeys = Object.keys(formikValues);
  const sortedFirstLayerKeys = firstLayerKeys.sort((a, b) => {
    const aIndex = orderedKeys.indexOf(a);
    const bIndex = orderedKeys.indexOf(b);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });

  console.log('sortedFirstLayerKeys', sortedFirstLayerKeys);

  const dotNotationErrors = transformObjectToDotNotation(formikErrors);
  return sortErrors(dotNotationErrors, sortedFirstLayerKeys);
};
