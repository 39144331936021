import React from 'react';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function InputWithTooltip({ input, message, addedStyle }) {
  const [, globalActions] = useGlobal();

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleOpenInfo = () => async () => {
    await sleep(100);
    globalActions.setSnackbarMessage(
      { message, severity: 'info' },
    );
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 1,
      alignItems: 'center',
      ...addedStyle,
    }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {input}
      </Box>
      <IconButton
        onClick={handleOpenInfo('some_text')}
      >
        <InfoIcon />
      </IconButton>
    </Box>
  );
}
