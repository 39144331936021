// eslint-disable-next-line import/prefer-default-export
export const initialValues = {
  contractConcludedAccordingToTheFrenchLaw: false,
  keys: false,
  registrationCertificate: false,
  technicalControlReport: false,
  pledgeCertificateDocument: null,
  europeanConformityCertificate: null,
  knowledgeNonPayment: false,
};
