/* eslint-disable max-len */
import React from 'react';

export default function VehicleSaleIcon({ size = 18, color }) {
  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 307.4 306.2" xmlns="http://www.w3.org/2000/svg">
      <path className="cls-1" d="M191.4,87.8c1.9,2.2,1.7,5.7-.6,8.3-15.6,14.7-39.3,17.1-57.2,6.8-12-6.4-19.5-16.7-24.1-29.4-.5-2.1-1.9-2.9-4-2.4-2.1.5-4.2.4-6.2.2-3.4-.3-5.4-1.8-5.2-4.5s1.7-4.7,4.5-5.2c2.1-.5,4.2-.4,6.2-.2,1.4.1,2.1-.5,1.5-2,.1-1.4-.4-3.5-.3-4.9.1-1.4-.5-2.1-2-1.5-2.1-.2-3.5.4-5.6.3-3.4-.3-5.4-1.8-5.9-4.6.2-2.8,1.7-4.7,5.2-5.1,2.1.2,4.2-.4,6.2-.2,2.1.2,2.8-.5,3-2.5,1.9-7.5,5.2-14.1,9.8-20.7,16.8-21.6,47.5-25.5,69.2-9.4,1.3.8,1.9,2.2,2.5,3.6.5,2.1-.4,4.8-2.5,6-2.2,1.2-4.9,1-6.9-.5-4-2.4-7.3-4.7-11.3-6.4-21-6.4-41.8,4.5-48.9,25.4q-.9,2.7,1.9,2.2c13.2-.4,26.4-1.5,40.2-1.9.7,0,2.1.2,2.8.2,2.8.2,4.7,2.4,4.5,4.5-.2,2.1-1.7,4-3.8,4.6-.7,0-2.1-.2-2.8.5-13.2.4-26.4,1.5-38.9,2q-6.2.2-6,6.5c-.2,2.1.5,2.1,2.6,2.3,7.6-.1,14.6-1,22.2-1.1h7.6c4.9-.5,9.7-.8,13.9-.5,4.2-.4,6.1,1.8,5.9,4.6.5,2.8-1.7,4.7-5.9,5.1l-3.5.4c-12.5.5-25.7.9-38.2,2-1.4-.1-2.8-.2-1.5,2,6.5,15,20.2,25.7,36.8,25.5,9.7,0,18.2-3.5,24.9-9.9,4.3-2.4,7.8-2.9,9.7,0Z" />
      <path className="cls-2" d="M291,218.7h16.5v-5.5c0-11-7.5-20.5-18-23h-.5c-16-4.5-33-7.5-49.5-9.5l-36.5-36.5c-6.5-6.5-18-11-27-11h-81c-9,0-20.5,5-26.5,11l-36.5,36.5h-12.5c-11,0-19.5,9-19.5,19.5v11.5h16c3.5,0,6.5,3,6.5,6.5s-3,6.5-6.5,6.5H0v28c0,11,9,19.5,19.5,19.5h9c2,13,10.5,24,23,30,5.5,2.5,11,3.5,16,3.5,15.5,0,30-9,36.5-23.5,1.5-3,2.5-6.5,3-9.5h87.5c2,13,10.5,24,23,30,5.5,2.5,11,3.5,16,3.5,15.5,0,30-9,36.5-23.5,1.5-3,2.5-6.5,3-9.5h10c13.5,0,24-11,24-24v-16h-16.5c-3.5,0-6.5-3-6.5-6.5s3.5-8,7-8ZM250,229.2c-.5,0-1-.5-1-.5.5,0,1,0,1,.5ZM248.5,228.2q-.5,0-1-.5t1,.5ZM247,227.7s-.5,0-1-.5c.5.5,1,.5,1,.5ZM246,227.2h-.5.5ZM244.5,227.2h-.5c0-.5.5-.5.5,0ZM243.5,226.7h-.5.5ZM242,226.2h-1,1ZM161,145.7h14.5c5.5,0,14,3.5,18,7.5l27,27h-59.5v-34.5ZM97,272.2c-3,13.5-15,23.5-29.5,23.5s-26.5-10-29.5-23.5c-.5-2-.5-4.5-.5-6.5,0-16.5,13.5-30,30-30s30,13.5,30,30,0,4-.5,6.5ZM148,180.2h-61.5l13-34.5h48v34.5h.5ZM175.5,205.7h-9c-3.5,0-6.5-3-6.5-6.5s3-6.5,6.5-6.5h9c3.5,0,6.5,3,6.5,6.5s-3,6.5-6.5,6.5ZM263.5,272.2c-3,13.5-15,23.5-29.5,23.5s-26.5-10-29.5-23.5c-.5-2-.5-4.5-.5-6.5,0-16.5,13.5-30,30-30s30,13.5,30,30c0,2,0,4-.5,6.5Z" />
    </svg>

  );
}
