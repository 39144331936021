import React from 'react';
import {
  Box, TextField, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';

export default function VehicleMaintenance({ formik, isReadOnly }) {
  const { t } = useTranslation();

  const handleHasMaintenanceBookBookChange = (event, newAlignment) => {
    formik.setFieldValue('vehicle.maintenance.hasMaintenanceBook', newAlignment);
  };

  const handleWithPhysicalBookChange = (event, newAlignment) => {
    formik.setFieldValue('vehicle.maintenance.maintenanceBookPhysical', newAlignment);
  };

  const handleInvoicesPhysicalChange = (event, newAlignment) => {
    formik.setFieldValue('vehicle.maintenance.invoicesPhysical', newAlignment);
  };

  const hiddenDefectsInfo = t('hidden_deffects_info', { returnObjects: true });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}
    >
      <Typography variant="h6">{`1.3 ${t('saleForm.vehicle.maintenance.title')} : `}</Typography>

      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography>{`${t('saleForm.vehicle.maintenance.hasMaintenanceBook')}`}</Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.maintenance.hasMaintenanceBook}
          exclusive
          onChange={handleHasMaintenanceBookBookChange}
          aria-label="vehicle.maintenance.hasMaintenanceBook"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <InputFileUpload
        isReadOnly={isReadOnly}
        fileBaseName={t('fileName.digitalMaintenanceBook')}
        formik={formik}
        field="vehicle.maintenance.maintenanceBookDigital"
        label={`${t('saleForm.vehicle.maintenance.maintenanceBookDigital')} : `}
        accept="image/jpeg,image/png,application/pdf"
      />

      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography sx={{ textAlign: 'left' }}>
          {`${t('saleForm.vehicle.maintenance.maintenanceBookPhysical')} : `}
        </Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.maintenance.maintenanceBookPhysical}
          exclusive
          onChange={handleWithPhysicalBookChange}
          aria-label="vehicle.maintenance.maintenanceBookPhysical"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.maintenance.maintenanceAndRepairInvoices_info')}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t('fileName.maintenanceAndRepairInvoices')}
            formik={formik}
            field="vehicle.maintenance.maintenanceAndRepairInvoices"
            label={`${t('saleForm.vehicle.maintenance.maintenanceAndRepairInvoices')} : `}
            accept="image/jpeg,image/png,application/pdf"
            maxFiles={5}
          />
          )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={t('saleForm.vehicle.maintenance.invoicesPhysical_info')}
        input={(
          <Box sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
          }}
          >
            <Typography sx={{ textAlign: 'left' }}>
              {`${t('saleForm.vehicle.maintenance.invoicesPhysical')} : `}
            </Typography>
            <ToggleButtonGroup
              disabled={isReadOnly}
              color="primary"
              value={formik.values.vehicle.maintenance.invoicesPhysical}
              exclusive
              onChange={handleInvoicesPhysicalChange}
              aria-label="vehicle.maintenance.invoicesPhysical"
              size="small"
            >
              <ToggleButton value>{t('yes')}</ToggleButton>
              <ToggleButton value={false}>{t('no')}</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          )}
      />

      {/* Additional Info field */}
      <TextField
        disabled={isReadOnly}
        label={t('saleForm.vehicle.maintenance.additionalInfo')}
        name="vehicle.maintenance.additionalInfo"
        multiline
        rows={4}
        value={formik.values.vehicle.maintenance.additionalInfo}
        onChange={formik.handleChange}
      />
      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={(
          <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
            {hiddenDefectsInfo.map((line, index) => (
              <div key={line}>
                {[0, 4].includes(index) ? <strong>{line}</strong> : line}
              </div>
            ))}
          </Typography>
        )}
        input={(
          <AgreeCheckMark
            isReadOnly={isReadOnly}
            formik={formik}
            field="vehicle.maintenance.allInfoGivenCheck"
            label={t('saleForm.vehicle.maintenance.allInfoGivenCheck')}
            extraText={t('saleForm.vehicle.maintenance.allInfoGivenCheckExtra')}
          />
      )}
      />
    </Box>
  );
}
