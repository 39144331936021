import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import autonoom from 'files/logo_pour_fond_clair.svg';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ScrollToTop from 'components/ScrollToTop';
import BuyerForm from './buyerForm/BuyerForm';
import SaleForm from './sellerForm/SaleForm';
import SellerSaleFormalitiesForm from './sellerSaleFormalitiesForm/SellerSaleFormalitiesForm';

const steps = ['step1', 'step2', 'step3'];

export default function VehicleSalePage() {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { bothPartiesPresent } = locationState || {};
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const [contract, setContract] = useState(undefined);

  const handleNext = (newContractId, newContract) => {
    if (newContractId) {
      setContract((prevContract) => ({
        ...prevContract,
        ...newContract,
        id: newContractId,
      }));
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <SaleForm bothPartiesPresent onPreviousStep={handleBack} onNextStep={handleNext} />;
      case 1:
        return (
          <BuyerForm
            bothPartiesPresent
            onPreviousStep={handleBack}
            onNextStep={handleNext}
            contract={contract}
          />
        );
      case 2:
        return (
          <SellerSaleFormalitiesForm
            bothPartiesPresent
            onPreviousStep={handleBack}
            onNextStep={handleNext}
            contract={contract}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  if (!bothPartiesPresent) {
    return <SaleForm />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            width: 130, height: 'auto', alignSelf: 'center', cursor: 'pointer',
          }}
          src={autonoom}
          alt="Autonoom Logo"
          onClick={() => navigate('/')}
        />
        <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>{t('vehicle_sale_contract')}</Typography>
        <Stepper sx={{ width: '100%' }} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={index <= activeStep}>
              <StepLabel>{t(`vehicle_sale_form_steps.${label}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ mt: 12, width: '100%' }}>
        {getStepContent(activeStep)}
      </Box>
    </Box>
  );
}
