import React, { useState } from 'react';
import {
  Box, Tabs, Tab, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EntityDetails from 'components/customInputs/EntityDetails';
import IndividualDetails from 'components/customInputs/IndividualDetails';

export default function BuyerDetails({ formik, isReadOnly }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(formik.values.buyer.buyerType);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    formik.setFieldValue('buyer.buyerType', newValue);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 1,
    }}
    >
      <Typography variant="h4">{`4. ${t('buyerForm.buyer.title')}`}</Typography>
      <Typography variant="h6">{`${t('buyerForm.buyer.subtitle')} : `}</Typography>
      <Tabs
        sx={{ alignSelf: 'center', mb: 2, mt: 2 }}
        value={value}
        onChange={handleTabChange}
        aria-label="buyer type tabs"
      >
        <Tab label={t('buyerForm.buyer.individual.individual')} value="individual" />
        <Tab label={t('buyerForm.buyer.entity.entity')} value="entity" />
      </Tabs>

      {value === 'individual' && (
      <Box>
        <IndividualDetails formik={formik} isReadOnly={isReadOnly} formName="buyerForm" entityName="buyer" />
      </Box>
      )}
      {value === 'entity' && (
      <Box>
        <EntityDetails formik={formik} isReadOnly={isReadOnly} formName="buyerForm" entityName="buyer" />
      </Box>
      )}
    </Box>

  );
}
