import React from 'react';
import {
  Box, IconButton, Paper, Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { displayDate } from 'components/utils';

export default function ContractListItem({
  itemData, onClick,
}) {
  const { t } = useTranslation();
  const statusColor = () => {
    switch (itemData.state) {
      case 'ownerSubmittedFirstForm':
        return '#437A89';
      case 'completed':
        return '#1db954';
      case 'sellerSaleFormalitiesCompleted':
        return '#1db954';
      default:
        return 'text.primary';
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'default',
        width: '100%',
        p: 3,
      }}
      onClick={onClick ? () => onClick(itemData) : undefined}
    >

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
      }}
      >
        <Box sx={{
          display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column',
        }}
        >
          <Typography sx={{ fontWeight: 'bold', width: 200 }}>
            {itemData.ownerForm.values.vehicle.model}
          </Typography>
          <Typography sx={{ width: 200 }}>
            {displayDate(itemData.ownerForm.values.vehicleSaleDetails.deliveryDateRange.start)}
          </Typography>
        </Box>

        <Typography
          sx={{
            color: statusColor(),
            width: { xs: 200, md: 400 },
          }}
        >
          {t(`state_display.${itemData.type}.${itemData.state}`)}
        </Typography>

      </Box>

      {onClick && (
        <IconButton size="small">
          <ArrowForwardIosIcon />
        </IconButton>
      )}

    </Paper>
  );
}
