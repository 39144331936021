import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import useGlobal from 'global-state/store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function OurDrawer({ open, toggleDrawer }) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          key="home"
          disablePadding
          component={Link}
          to="/alpha"
          button
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('home')} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="my_contracts"
          disablePadding
          component={Link}
          to="/myContracts"
          button
        >
          <ListItemButton>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={t('my_contracts')} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="my_account"
          disablePadding
          component={Link}
          to="/menuAccount"
          button
        >
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('my_account')} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="contact_us"
          disablePadding
          component={Link}
          to="/menuContact"
          button
        >
          <ListItemButton>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={t('contact.contact_us')} />
          </ListItemButton>
        </ListItem>
        {globalState.roles.includes('admin')
          && (
          <ListItem
            component={Link}
            to="/admin"
            button
            key="admin"
          >
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          )}

      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {list()}
    </SwipeableDrawer>

  );
}
